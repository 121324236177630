import React from "react";

const textWithLinkItems = (props) => {
  const findTextToLink = (text, linkObject) => {
    let searchWords = Object.keys(linkObject);
    let tempLowestIndex = Number.MAX_SAFE_INTEGER;
    let tempLowestWord;
    let found = false;
    for (let i = 0; searchWords.length > i; i++) {
      let tempIndex = text.search(searchWords[i]);
      if (tempIndex < tempLowestIndex && tempIndex !== -1) {
        tempLowestIndex = tempIndex;
        tempLowestWord = searchWords[i];
        found = true;
      }
    }
    if (found) {
      return [
        text.substring(0, tempLowestIndex),
        text.substring(
          tempLowestIndex,
          tempLowestIndex + tempLowestWord.length
        ),
        text.substring(tempLowestIndex + tempLowestWord.length),
      ];
    } else {
      return false;
    }
  };

  let tempText = props.text,
    LINKS = props.links,
    tempTextArray = [];

  while (findTextToLink(tempText, LINKS)) {
    let t = findTextToLink(tempText, LINKS);

    let textBefore = t[0],
      linkedWord = (
        <a
          key={tempTextArray.length}
          target="_blank"
          rel="noopener noreferrer"
          href={LINKS[t[1]]}
        >
          {t[1]}
        </a>
      ),
      textAfter = t[2];

    tempTextArray.push(textBefore);
    tempTextArray.push(linkedWord);
    tempText = textAfter;
  }
  tempTextArray.push(tempText);

  return <p> {tempTextArray} </p>;
};

export default textWithLinkItems;
