import React from "react";
import classes from "./Button.module.css";

export default ({ title, ...props }) => {
  return (
    <button className={classes.button} onClick={props.toggleButton}>
      {title}
    </button>
  );
};
