import React from "react";
import { Viz } from "../../visualisations/index";
// import classes from "./css/Article.module.css";

export default ({ d }) => {
  switch (d.chart) {
    case "map":
      return <Viz.ArticleMap />;
    case "treemap":
      return <Viz.Treemap />;
    default:
      return null;
  }
};
