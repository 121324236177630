import React, { useState, useEffect, useRef } from "react";
import { BackdropMobile } from "../../../static";
// import { Backdrop, BackdropMobile } from "../../../static";
import { MobileHeaderWrapper, Header } from "../index";
import classes from "./css/Headers.module.css";

const Headers = (props) => {
  const { match, location, i18n, baseURL } = props;
  //detect mouseOver event to set nav ids as state
  //detect mobileoggle
  const [mouseOver, setMouseOver] = useState(null);
  const [mobileToggle, setMobileToggle] = useState(false);

  //Set mobile toggle value to false to set sidebar display
  const prevLocation = usePrevious(location.pathname);
  useEffect(() => {
    const handleResize = () =>
      window.innerWidth >= 960 ? setMobileToggle(false) : null;
    window.addEventListener("resize", handleResize);
    // Set state to default when new page is open
    if (location.pathname !== prevLocation) {
      setMouseOver(null);
      setMobileToggle(false);
    }
    //Disalbe scroll when mobile nav is open
    mobileToggle
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [prevLocation, mobileToggle, location.pathname]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  return (
    <header className={classes.MainHeader}>
      <Header
        data={i18n}
        baseURL={baseURL}
        mouseOver={mouseOver}
        pathname={location.pathname}
        locale={match.params.locale}
        toggleHover={(value) => setMouseOver(value)}
        toggleLeave={() => setMouseOver(null)}
        mobileHeaderToggle={() => setMobileToggle(!mobileToggle)}
      />
      {/* <Backdrop hovered={mouseOver} /> */}
      <MobileHeaderWrapper
        data={i18n}
        mobileToggle={mobileToggle}
        pathname={location.pathname}
        locale={match.params.locale}
        closed={() => setMobileToggle(!mobileToggle)}
      />
      <BackdropMobile
        mobileToggle={mobileToggle}
        handleBackdrop={() => setMobileToggle(!mobileToggle)}
      />
    </header>
  );
};

export default Headers;
