import React from "react";
import { NavLink } from "react-router-dom";

export default ({ type, url, style, item, exact, active, children }) => {
  switch (type) {
    case "nav":
      return (
        <NavLink
          to={url}
          exact={exact}
          activeClassName={active}
          className={style}
        >
          {item}
        </NavLink>
      );
    case "main_nav_item":
      return (
        <NavLink to={url} className={style} exact={exact}>
          {item}
        </NavLink>
      );
    case "container":
      return (
        <NavLink to={url} className={style} exact={exact} onClick = {() => {
          window.scrollTo(0, 0);
        }}>
          {children}
        </NavLink>
      );
    case "containerDirectLink":
      return (
            <a href={url}>
              {children}
            </a>
      );
    default:
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {item}
        </a>
      );
  }
};
