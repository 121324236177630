import axios from 'axios';

let URL = process.env.REACT_APP_DEV;
if (process.env.NODE_ENV === 'production') URL =process.env.REACT_APP_PROD;

export const fetchInstance = axios.create({
    baseURL: URL
});

export const i18Instance = axios.create({
    baseURL: URL
});

export const countriesInstance = axios.create({
    baseURL: URL
});

export const researchInstance = axios.create({
    baseURL: URL
});

