import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout/index";
import { Grid, GridWrapper, GridContainer } from "../../modules/grids/index";
import BackgroundImage from "../../assets/images/bg__main.jpg";
import * as actions from "../../store/actions/index";
import classes from "./css/Main.module.css";
import ShowMore from 'react-show-more-button';

export default (props) => {
  const { url, params } = props.match;
  const { baseURL } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.main.data);
  const locale = params.locale;
console.log(props)
  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchMainData(params.locale));
  }, [params.locale, dispatch]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);
  if (Object.keys(data).length === 0) {
    return <div></div>;
  } else {
    let presslist = [];
    data.media.list.forEach(() => {
      presslist.push("xs");
    });
    return (    
      <ContainerType.Image data={data} bg={BackgroundImage} locale={locale} style={{position: 'relative'}}>
        <GridWrapper type="main">
          <GridContainer style={classes.MainContainer}>
            <Grid.Grid
              main
              match={url}
              baseURL={baseURL}
              data={data.explore.list}
              type={["md", "md", "md"]}
            />
          </GridContainer>
        </GridWrapper>
        <GridWrapper type="intro">

          <GridContainer
              style={classes.Container}
              header
              title={data.newsletter.title}
              type="twelve"
          >
            <div className={classes.subscribe}>
              {locale==="en" ? "Subscribe to our newsletter!" : "Abonnez-vous à nos lettres d'information !"}
              <div></div>
              <a href={"https://oecd.us4.list-manage.com/subscribe?u=5aa4680998eddebe5f4ce7065&id=17db0c31dd"}> {locale==="en" ? "Subscribe" : "S'abonner"} </a>
            </div>

            <Grid.AboutNewsletter
                data={data.newsletter.list}
                style={classes.Item}
            />
          </GridContainer>


          <GridContainer
            header
            style={classes.Container}
            title={data.narratives.title}
            type="twelve"
          >
            <Grid.Grid
              match={url}
              baseURL={baseURL}
              data={data.narratives.list}
              type={["lg", "lg", "lg", "lg"]}
            />
          </GridContainer>
          <GridContainer
            style={classes.Container}
            header
            match={url}
            title={data.research.title}
            type="twelve"
          >
            <Grid.Grid
              match={url}
              baseURL={baseURL}
              data={data.research.list}
              type={["md", "md", "md"]}
            />
          </GridContainer>
        </GridWrapper>
        <GridWrapper>
          <GridContainer
            header
            style={classes.Container}
            match={url}
            title={data.keyreport.title}
            type="twelve"
          >
            <Grid.Grid
              match={url}
              baseURL={baseURL}
              data={data.keyreport.list}
              type={["md", "md", "md"]}
            />
          </GridContainer>
        </GridWrapper>
        <GridWrapper>
          <GridContainer
            header
            style={classes.Container}
            match={url}
            title={data.video.title}
            type="twelve"
          >
            <Grid.Grid
              match={url}
              baseURL={baseURL}
              data={data.video.list}
              type={["md", "md", "md"]}
            />
          </GridContainer>
        </GridWrapper>
        <GridWrapper>
          <GridContainer
            header
            match={url}
            title={data.media.title}
            type="twelve"
          >
            <ShowMore maxHeight={390} styleButton={{background: "none", color:"black", border: 0}}>
            <Grid.Grid match={url} data={data.media.list} type={presslist} />
            </ShowMore>
          </GridContainer>

        </GridWrapper>

        <GridWrapper>
          <GridContainer
              header
              match={url}
              title={data.press.title}
              type="twelve"
          >
            <ShowMore maxHeight={390} styleButton={{background: "none", color:"black", border: 0}}>
              <Grid.Grid match={url} data={data.press.list} type={presslist} />
            </ShowMore>
          </GridContainer>

        </GridWrapper>

        {/*<ShowMore maxHeight={600} styleButton={{background: "none", color:"black", border: 0}}>*/}
        {/*<GridContainer*/}
        {/*    style={classes.EventsContainer}*/}
        {/*    header*/}
        {/*    title={data.events.title}*/}
        {/*    type="twelve"*/}
        {/*>*/}
        {/*  <Grid.Grid*/}
        {/*      match={url}*/}
        {/*      baseURL={baseURL}*/}
        {/*      data={data.events.list}*/}
        {/*      type={[""]}*/}
        {/*  />*/}
        {/*</GridContainer>*/}
        {/*</ShowMore>*/}


        <GridWrapper>
          <GridContainer
            header
            style={classes.Container}
            match={url}
            title={data.digitalstory.title}
            type="twelve"
          >
            <Grid.Grid
              match={url}
              baseURL={baseURL}
              data={data.digitalstory.list}
              type={["md", "md", "md"]} />
          </GridContainer>
        </GridWrapper>
      </ContainerType.Image>
    );
  }
};
