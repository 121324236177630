import { fetchInstance } from "../../axios";

function fetchResearchDataFail(err) {
  return {
    type: "FETCH_RESEARCH_DATA_FAIL",
  };
}

export function fetchStoryData(locale, story) {
  return (dispatch) => {
    fetchInstance
      .get(`/json/stories/${locale}_${story}.json`)
      .then((res) => {
        dispatch({
          type: "FETCH_STORY_DATA",
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch(fetchResearchDataFail(err));
      });
  };
}

export function clearStoryData() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_STORY_DATA",
    });
  };
}

export function fetchResearchData(locale) {
  return (dispatch) => {
    fetchInstance
      .get(`/json/config/${locale}_research.json`)
      .then((res) => {
        dispatch({
          type: "FETCH_RESEARCH_DATA",
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch(fetchResearchDataFail(err));
      });
  };
}
