import React from "react";
import { Treemap } from "d3plus-react";

export default ({ height, config, legendSort }) => {
  return (
    <div style={{ height: `${height}px`, width: "100%" }}>
      <Treemap config={{ ...config, legend: false }} legendSort={legendSort} />
    </div>
  );
};
