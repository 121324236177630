import React from "react";
import classes from "./Icons.module.css";
export default ({ title, icon, dark, click }) => {
  let buttonStyle = dark
    ? [classes.Button, classes.dark].join(" ")
    : classes.Button;
  return (
    <button className={buttonStyle} onClick={click}>
      <span className={[classes.icon, classes[`icon--${icon}`]].join(" ")}>
        <svg>
          {title && <title>{title}</title>}
          <use xlinkHref={`#icon--${icon}`}></use>
        </svg>
      </span>
    </button>
  );
};
