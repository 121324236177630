import React from 'react';


const NotificationBanner = ({ locale }) => {
    const isEnglish = locale === "en";
    const message = isEnglish
      ? "Africapolis Update: Explore projected urban through 2050 for over 11 000 urban agglomerations!"
      : "Mise à jour d'Africapolis : Explorez les projections urbaines jusqu'en 2050 pour plus de 11 000 agglomérations urbaines!";
    const downloadLink = isEnglish ? "/en/data?menu=download" : "/fr/data?menu=download";
  
    return (
      <div className="notification-banner">
        <span>
          {message}{' '}
          <a href={downloadLink} className="download-link">
            {isEnglish ? "Data download" : "Téléchargez les données"}
          </a>
        </span>
      </div>
    );
  };

// const NotificationBanner = ({ locale }) => {
//     const isEnglish = locale === "en";
//     const boldText = isEnglish
//       ? "Africapolis Update:"
//       : "Mise à jour d'Africapolis :";
//     const regularText = isEnglish
//       ? " Explore projected urban through 2050 for over 11 000 urban agglomerations!"
//       : " Explorez les projections urbaines jusqu'en 2050 pour plus de 11 000 agglomérations urbaines!";
//     const downloadLink = isEnglish ? "/en/data?menu=download" : "/fr/data?menu=download";
  
//     return (
//       <div className="notification-banner">
//         <span>
//           <span className="bold-text">{boldText}</span>
//           {regularText}{' '}
//           <a href={downloadLink} className="download-link">
//             {isEnglish ? "Data download" : "Téléchargez les données"}
//           </a>
//         </span>
//       </div>
//     );
//   };

  

export default NotificationBanner;
