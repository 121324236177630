import React from 'react';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import { 
    i18nReducer, 
    MainReducer, 
    CountriesReducer, 
    ResearchReducer,
    AboutReducer,
    DataReducer, 
} from './store/reducers';
import './index.css';



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    i18n: i18nReducer,
    main: MainReducer,
    countries: CountriesReducer,
    research: ResearchReducer,
    data: DataReducer,
    about: AboutReducer,
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = <Provider store={store}><App/></Provider>; 
render(app, document.getElementById('root'));

serviceWorker.unregister();
