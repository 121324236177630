
import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom';

const DynamicScrollToTop = (props) => {

	const { hash, pathname } = props.location;
	const { locale } = props.match.params;
	const dontScrollIntoViewPaths = [ `/${locale}/countries`, `/${locale}/countries/country-report`];  

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevPathname = usePrevious(pathname)

	useEffect(() => {
		if (props.history.action === 'POP') {
			return;
		}

		if (hash) {
			let element = document.querySelector(hash);
			if (element) element.scrollIntoView({block: 'start', behavior: 'smooth'});
		} else if ( dontScrollIntoViewPaths.includes(pathname) 
			&& dontScrollIntoViewPaths.includes(prevPathname) ) {
			return;
		}else{
			window.scrollTo(0, 0);
		}
	});
	return <div/>
};

export default withRouter(DynamicScrollToTop);