import React from "react";
import classes from "./Layout.module.css";
import { yearRange } from "../../../global";
import Mixer from "../../../visualisations/components/graphComponents/Mixer/Mixer";
import Visualisation from "../../../visualisations/components/graphComponents/Visualisation/Visualisation";

export default (props) => {
  const { data, locale, query, keyFigureList, history } = props;
  let temp = {};
  let data_result = [];
  let countryListR = [];
  let regionList = [];
  let idList = [
    "totalPop",
    "urbanPop",
    "ruralPop",
    "totalPopGrowth",
    "urbanPopGrowth",
    "ruralPopGrowth",
    "uSurf",
    "urbanLevel",
    "ADBA",
    "mPop",
    "numAgglos",
  ];
  let countryListFR = ["Angola","Burundi","Bénin","Burkina Faso","Botswana","République centrafricaine","Côte d’Ivoire","Cameroun","République démocratique du Congo","République du Congo","Comores","Cabo Verde","Djibouti","Algérie","Égypte","Érythrée","Éthiopie","Gabon","Ghana","Guinée","Gambie","Guinée-Bissau","Guinée équatoriale","Kenya","Libéria","Libye","Lesotho","Maroc","Madagascar","Mali","Mozambique","Mauritanie","Maurice","Malawi","Mayotte","Namibie","Niger","Nigéria","Réunion","Rwanda","Soudan","Sénégal","Sierra Leone","Somalie","Soudan du Sud","Sao Tomé-et-Principe","Royaume d’Eswatini","Seychelles","Tchad","Togo","Tunisie","Tanzanie","Ouganda","Afrique du Sud","Zambie","Zimbabwe","Afrique","Afrique centrale","Afrique de l'Est","Afrique du Nord","Afrique australe","Afrique de l'Ouest",
    'Union du Maghreb arabe',
    'Marché commun de l’Afrique orientale et australe',
    'Communauté des États Sahélo Sahariens',
    'Communauté d’Afrique de l’Est',
    'Communauté économique des États de l’Afrique centrale',
    'Communauté économique des États de l’Afrique de l’Ouest',
    'Autorité Intergouvernementale sur le Développement',
    'Communauté de développement de l’Afrique australe',
    'Union monétaire ouest-africaine'];


  idList.forEach((i) =>
    yearRange.map((j, k) => data_result.push({ ID: i, Year: j, Index: k }))
  );

  data.countryData.forEach((i, j) => {

    if (j < 56) {
      locale==="en"? countryListR.push({ label: i.title, value: i.title }) : countryListR.push({ label: countryListFR[j], value: i.title })
    } else {
      locale==="en"? regionList.push({ label: i.title, value: i.title }) : regionList.push({ label: countryListFR[j], value: i.title })
    }
    Object.keys(i.data).forEach((d) => {
      if(typeof(i.data[d]) !== "string"){
      i.data[d].map((e, f) =>
        data_result.map((k) =>
          k.ID === d && k.Index === f
            ? query.type === "growth"
              ? f === 0
                ? (k[i.title] =
                    Math.round(
                      (Math.pow(i.data[d][1] / i.data[d][0], 1 / 10) - 1) *
                        10000
                    ) / 100)
                : (k[i.title] =
                    Math.round(
                      (Math.pow(e / i.data[d][f - 1], 1 / 10) - 1) * 10000
                    ) / 100)
              : query.type === "baseYear"
              ? (k[i.title] = Math.round((e / i.data[d][4]) * 100))
              : (k[i.title] = e)
            : null
        )
      );
      temp[d] = i.data[d];
    }
  });
  });

  data_result.map((i) => (i["type"] = query.type));


  let options = [
    {
      label: locale === "en" ? "Regions" : "Régions",
      options: regionList.sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: locale === "en" ? "Countries" : "Pays",
      options: countryListR.sort((a, b) => a.label.localeCompare(b.label)),
    },
  ];

  if (data.length === 0 || Object.entries(query).length < 2) {
    return <div> Loading ...</div>;
  } else {
    return (
      console.log("query: ", query),
      <div className={classes["data-browser"]}>
        <Mixer
          keyFigureList={keyFigureList}
          history={history}
          countryList={options}
          language={locale}
          query={query}
        />
        <Visualisation
          query={query}
          history={history}
          language={locale}
          data={data_result}
          keyFigureList={keyFigureList}
          countryListR={countryListR}
          countryList={options}
          agglosData={data.agglosData}
        />
      </div>
    );
  }
};
