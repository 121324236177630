import React from "react";
import { AsyncSelect, SelectorStyles } from "../../../selectors";
import classes from "./css/CountryreportHeader.module.css";
import { HashLink } from "react-router-hash-link";

export default ({
  location,
  visibleSection,
  locale,
  country,
  data,
  query,
  countryList,
  history,
  mobileHeaderToggle,
}) => {
  return (
    <header className={classes.ExploreHeader_Wrapper}>
      <div className={classes.ExploreHeader_Container}>
        <div className={classes.ExploreHeader_Navigation}>
          <div className={classes.ExploreHeader_Selector}>
            <AsyncSelect
              filterVariable={country}
              styles={SelectorStyles.CountryReport}
              options={countryList.countryList.map((d) => ({
                value: d.C_ID,
                label: d.item,
              }))}
              push={(value) =>
                history.push({
                  pathname: `/${locale}/country-report/${value.label}`,
                })
              }
            />
          </div>
          <div className={classes.Index}>
            <HashLink
              className={`header_link ${
                visibleSection === "keyfigures"
                  ? classes.selected
                  : classes.unselected
              }`}
              smooth
              to={location.pathname + "#keyfigures"}
            >
              {locale === "en" ? "Key figures" : "Chiffres clés"}{" "}
            </HashLink>
            <HashLink
              className={`header_link ${
                visibleSection === "overview"
                  ? classes.selected
                  : classes.unselected
              }`}
              smooth
              to={location.pathname + "#overview"}
            >
              {locale === "en" ? "Context" : "Situation"}
            </HashLink>
            <HashLink
              className={`header_link ${
                visibleSection === "urbandynamics"
                  ? classes.selected
                  : classes.unselected
              }`}
              smooth
              to={location.pathname + "#urbandynamics"}
            >
              {locale === "en" ? "Urban dynamics" : "Dynamiques"}{" "}
            </HashLink>
            <HashLink
              className={`header_link ${
                visibleSection === "evolutionofpopulation"
                  ? classes.selected
                  : classes.unselected
              }`}
              smooth
              to={location.pathname + "#evolutionofpopulation"}
            >
              {locale === "en" ? "Notable features" : "Faits marquants"}{" "}
            </HashLink>
            <HashLink
              className={`header_link ${
                visibleSection === "agglomerations"
                  ? classes.selected
                  : classes.unselected
              }`}
              smooth
              to={location.pathname + "#agglomerations"}
            >
              {locale === "en"
                ? "Territorial divisions"
                : "Découpages territoriaux"}{" "}
            </HashLink>
          </div>
        </div>
        <button className={classes.button} onClick={mobileHeaderToggle}>
          <div className={classes.DrawerToggle}></div>
          <div className={classes.DrawerToggle}></div>
          <div className={classes.DrawerToggle}></div>
        </button>
      </div>
    </header>
  );
};
