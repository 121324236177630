import React, { useEffect, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout/index";
import { Grid, GridWrapper, GridContainer } from "../../modules/grids/index";
import * as actions from "../../store/actions/index";
import classes from "./css/Countries.module.css";

export default (props) => {
  const { locale } = props.match.params;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.countries.data);

  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchCountriesData(locale));
  }, [locale, dispatch]);

  const url = props.location.pathname.split("/");
  const selectedMenu =
    url[url.length - 1] === "countries" ? "explore" : "country-report";

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);

  if (Object.keys(data).length === 0) {
    return null;
  } else {
    let setUrlType = "explore";
    let regionsList = (
        <>
      <GridContainer
        style={classes.Container}
        title={data.regions.title}
        type="twelve"
        header
      >
        <Grid.List
          type={setUrlType}
          style={classes.Item}
          locale={locale}
          data={data.regions.list}
        />
      </GridContainer>
    <GridContainer
        style={classes.Container}
        title={data.regionalEntities.title}
        type="twelve"
        header
    >
      <Grid.List
          type={setUrlType}
          style={classes.Item}
          locale={locale}
          data={data.regionalEntities.list}
      />
    </GridContainer>
        </>
    );

    if (selectedMenu === "country-report") setUrlType = "report";

    const disabledList =
      props.match.params.locale === "en"
        ? []
        : [
        ];

    let withDisalbedlist = data.countries.list.map((d) => ({
      ...d,
      disabled: disabledList.includes(d.C_ID) ? true : false,
    }));
    return (
      <ContainerType.Text {...props} data={data} selectedMenu={selectedMenu}>
        <Switch>
          <Switch>
            <Route path={`${props.match.path}/country-report`}>
              <GridWrapper>
                <GridContainer
                  style={classes.Container}
                  title={data.countries.title}
                  type="twelve"
                  header
                >
                  <Grid.List
                    type={setUrlType}
                    style={classes.Item}
                    locale={locale}
                    data={withDisalbedlist}
                    // data={data.countries.list}
                  />
                </GridContainer>
              </GridWrapper>
            </Route>
            <Route path={`${props.match.path}`}>
              <GridWrapper>
                {regionsList}
                <GridContainer
                  style={classes.Container}
                  title={data.countries.title}
                  type="twelve"
                  header
                >
                  <Grid.List
                    type={setUrlType}
                    style={classes.Item}
                    locale={locale}
                    data={data.countries.list}
                  />
                </GridContainer>
              </GridWrapper>
            </Route>
          </Switch>
        </Switch>
      </ContainerType.Text>
    );
  }
};
