import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Localiser.module.css";

export function Main({ data, pathname, mouseOver }) {
  let i18nStyle = classes.i18nStyle;
  // if (mouseOver !== null || pathname.length > 3) {
  //   i18nStyle = [classes.i18nStyle, classes.active].join(" ");
  // }
  if (pathname.length > 3) {
    i18nStyle = [classes.i18nStyle, classes.active].join(" ");
  }
  return data.map((d, idx) => (
    <li key={`i18n-${idx}`} className={classes.HeaderItem}>
      <NavLink className={i18nStyle} to={generateUrl(d.url, pathname)}>
        {d.item}
      </NavLink>
    </li>
  ));
}

export function Mobile({ data, pathname, mouseOver }) {
  let i18nStyle = classes.i18nStyle_Mobile;
  if (mouseOver !== null || pathname.length > 3) {
    i18nStyle = [classes.i18nStyle_Mobile, classes.active].join(" ");
  }
  return data.map((d, idx) => (
    <li key={`i18n-${idx}`} className={classes.HeaderItem_Mobile}>
      <NavLink className={i18nStyle} to={generateUrl(d.url, pathname)}>
        {d.item}
      </NavLink>
    </li>
  ));
}


function generateUrl(locale, path) {
  let url = path.substring(3) === "/explore"?  "/" + locale + path.substring(3) + "?country=Mali&poprange=1,2,3,4,5,6,7&year=2015" :
                                      "/data"?  "/" + locale + path.substring(3) + "?country=Angola&keyfigure=totalPop&type=abs&year=2015" :
                                        "/" + locale + path.substring(3)  
  return url;
}
