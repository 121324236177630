import React, { useEffect } from "react";
import { useMap } from "../../../customHooks";
import { pushQuery } from "../../global";
import {
  agglomeration_main,
  countryLabelEN,
    countryLabelFR,
  regionLabelEN,
  regionLabelFR
} from "./MapComponents/mapConfig";
import {
  idToISO3,
  setAgglosColor,
  setAgglosRadius,
} from "./MapComponents/mapFunctions";
import { bBoxById } from "./MapComponents/mapUtils";

export default ({
  locale,
  countryReport,
  selectedCountry,
  selectedAgglos,
  styles,
  history,
  query,
  layer,
  buildUpYear
}) => {
  /**
   * NEED TO FEED THIS CONFIG FROM OUTSIDE.
   */
  const countryLabelLanguage = locale ==="fr" ? countryLabelFR  : countryLabelEN;
  const regionLabelLanguage = locale ==="fr" ? regionLabelFR  : regionLabelEN;
  // const _ = require("lodash");
  const opacity2015 = 0.75
  const opacity2020 = 0.75

  const config = {
    tiles: [
      {
        id: "country",
        source: "africapolis_country",
        sourceLayer: "AFRICACONTINENT2020-0x9hmf",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.dt9a8kdp",
        mouseEvent: false,
      },
      {
        id: "agglomerations",
        source: "africapolis_agglos",
        sourceLayer: "Africapolis_agglomeration_202-6etzwi",
        type: "vector",
        layerType: "circle",
        url: "mapbox://mkmd.6un6bsfv",
        mouseEvent: false,
        feature: {
          paint: agglomeration_main.paint,
        },
      },
      {
        id: "countryLabel",
        source: "africapolis_country_label",
        sourceLayer: "Africa_country_official_point-5smbpe",
        type: "vector",
        layerType: "symbol",
        url: "mapbox://mkmd.9rvgto2u",
        mouseEvent: true,
        feature: {
          filter: countryLabelLanguage.filter,
          layout: countryLabelLanguage.layout,
          paint: countryLabelLanguage.paint,
        },
      },


      
      // {
      //   id: "regionLabel",
      //   source: "africapolis_region_label",
      //   sourceLayer: "region-78kdxj",
      //   type: "vector",
      //   layerType: "symbol",
      //   url: "mapbox://mkmd.ck91uxg28274q2voaakwaxzcg-4g4fr",
      //   mouseEvent: true,
      //   feature: {
      //     layout: regionLabel.layout,
      //     paint: regionLabel.paint,
      //   },
      // },
      {
        id: "regionLabel",
        source: "africapolis_region_label",
        sourceLayer: "Region",
        type: "vector",
        layerType: "symbol",
        url: "mapbox://mkmd.ck91uxg28274q2voaakwaxzcg-4g4fr",
        mouseEvent: true,
        feature: {
          layout: regionLabelLanguage.layout,
          paint: regionLabelLanguage.paint,
        }
      },
      {
        id: "africapolis-borders-2050",
        source: "afp-borders-2050",
        sourceLayer: "Africapolis_agglomeration_205-1q3hpo",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.96uxs7qq",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
      {
        id: "africapolis-borders-2045",
        source: "afp-borders-2045",
        sourceLayer: "Africapolis_agglomeration_204-5j1rqv",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.63wpagse",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
      {
        id: "africapolis-borders-2040",
        source: "afp-borders-2040",
        sourceLayer: "Africapolis_agglomeration_204-a5e0e5",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.3rmcaf5u",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
      {
        id: "africapolis-borders-2035",
        source: "afp-borders-2035",
        sourceLayer: "Africapolis_agglomeration_203-deen28",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.09wrwgwz",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
      {
        id: "africapolis-borders-2030",
        source: "afp-borders-2030",
        sourceLayer: "Africapolis_agglomeration_203-57ed1d",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.4vz3pg6u",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
      {
        id: "africapolis-borders-2025",
        source: "afp-borders-2025",
        sourceLayer: "Africapolis_agglomeration_202-5riva6",
        type: "vector",
        layerType: "fill",
        url: "mapbox://mkmd.4fym7po3",
        mouseEvent: false,
        feature: {
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.5,
            // "line-color": "#000000",
            // "line-width": 1.5
          },
          layout: {
            visibility: "none"
          }
        }
      },
        {
          id: "africapolis-borders-2015",
          source: "afp-borders-2015",
          sourceLayer: "ugs_polygons-ccxfku",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.b8gosgxx",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2015,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              // visibility: "none"
            }
          }
        },
        {
          id: "africapolis-borders-2020-1",
          source: "afp-borders-2020-1",
          sourceLayer: "2020_central1-4d5yjc",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.d8mdah0w",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2020,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              visibility: "none"
            }
          }
        },
        {
          id: "africapolis-borders-2020-2",
          source: "afp-borders-2020-2",
          sourceLayer: "2020_south1-7w09l2",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.107otb8d",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2020,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              visibility: "none"
            }
          }
        },
        {
          id: "africapolis-borders-2020-3",
          source: "afp-borders-2020-3",
          sourceLayer: "2020_south2-cflt39",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.7635vw17",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2020,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              visibility: "none"
            }
          }
        },
        {
          id: "africapolis-borders-2020-4",
          source: "afp-borders-2020-4",
          sourceLayer: "2020_central2-7ze9c3",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.cujtz5kz",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2020,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              visibility: "none"
            }
          }
        },
        {
          id: "africapolis-borders-2020-5",
          source: "afp-borders-2020-5",
          sourceLayer: "2020_North-8obvy0",
          type: "vector",
          layerType: "fill",
          url: "mapbox://mkmd.5wp3u8tw",
          mouseEvent: false,
          feature: {
            paint: {
              "fill-color": "#000000",
              "fill-opacity": opacity2020,
              // "line-color": "#000000",
              // "line-width": 1.5
            },
            layout: {
              visibility: "none"
            }
          }
        },






      
      

      ///////////////////////////////////////////////////////////////
      




    ],
  };

  // 1. useMap creates map based on sources and layers in config.

    const { map, mapContainerRef, popUp } = useMap(config, layer, buildUpYear, locale)
  const { poprange, year } = query;
  

  // Initialisation of style depending on new data (query)
  useEffect(() => {
    if (!map) return;
    if (selectedCountry) {
      let selectedCountryId =
        selectedCountry.id[1] === 0
          ? selectedCountry.id[2]
          : selectedCountry.id[1];
      let filterType = selectedCountry.id[1] === 0 ? "Region_ID" : "ISO3";
      let countryType =
        selectedCountry.id[1] === 0
          ? selectedCountry.id[2]
          : idToISO3(selectedCountryId);
      console.log(selectedCountryId)
      let bound =
          (selectedCountry.id[1] === 0 && selectedCountry.id[2] >= 6)
          ? [
              [-23.27551, 37.472863],
              [61.268128, -35.870773],
            ]
          : (selectedCountry.id[1] === 0 && selectedCountry.id[2] < 6) ?
              [
                [
                  bBoxById[selectedCountryId+56][0][0] - 6,
                  bBoxById[selectedCountryId+56][0][1],
                ],
                [
                  bBoxById[selectedCountryId+56][1][0],
                  bBoxById[selectedCountryId+56][1][1],
                ],
              ] :
              [
              [
                bBoxById[selectedCountryId][0][0] - 6,
                bBoxById[selectedCountryId][0][1],
              ],
              [
                bBoxById[selectedCountryId][1][0],
                bBoxById[selectedCountryId][1][1],
              ],
            ];

      map
        .fitBounds(bound,{animate: false})
        .setFilter("country", ["==", filterType, countryType])
        .setPaintProperty("country", "fill-color", "#e0e0e0")
        .setPaintProperty("country", "fill-opacity", [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          5,
          0,
        ])

        function updateBordersPaintProperties(map, year) {
          // Set fill-color and fill-outline-color for borders from 2020 to 2050
          const years = [2020, 2025, 2030, 2035, 2040, 2045, 2050];
          years.forEach(y => {                        
              map.setPaintProperty(`africapolis-borders-${y}`, "fill-color", setAgglosColor(y))
                 .setPaintProperty(`africapolis-borders-${y}`, "fill-outline-color", '#000000');
            }
          );
          
          map.setPaintProperty(`africapolis-borders-2015`, "fill-color", setAgglosColor(2015,true))
            .setPaintProperty(`africapolis-borders-2015`, "fill-outline-color", '#000000');
          
          map.setPaintProperty(`africapolis-borders-2020-1`, "fill-color", setAgglosColor(2020,true))
           .setPaintProperty(`africapolis-borders-2020-1`, "fill-outline-color", '#000000');
          map.setPaintProperty(`africapolis-borders-2020-2`, "fill-color", setAgglosColor(2020,true))
          .setPaintProperty(`africapolis-borders-2020-2`, "fill-outline-color", '#000000');
          map.setPaintProperty(`africapolis-borders-2020-3`, "fill-color", setAgglosColor(2020,true))
          .setPaintProperty(`africapolis-borders-2020-3`, "fill-outline-color", '#000000');
          map.setPaintProperty(`africapolis-borders-2020-4`, "fill-color", setAgglosColor(2020,true))
          .setPaintProperty(`africapolis-borders-2020-4`, "fill-outline-color", '#000000');
          map.setPaintProperty(`africapolis-borders-2020-5`, "fill-color", setAgglosColor(2020,true))
          .setPaintProperty(`africapolis-borders-2020-5`, "fill-outline-color", '#000000');


        }

        updateBordersPaintProperties(map,year)
        ;

    } else {
      
      map
        .setPaintProperty("country", "fill-color", "#e0e0e0")
        .setPaintProperty("country", "fill-opacity", [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          5,
          0,
        ]);
    }
  }, [map, countryReport, selectedCountry, locale]);

  useEffect(() => {
    if (!map) return;
    let selectedCountryId =
      selectedCountry.id[1] === 0
        ? selectedCountry.id[2]
        : selectedCountry.id[1];
    let filterType = selectedCountry.id[1] === 0 ? "Region_ID" : "ISO3";

    let countryType =
      selectedCountry.id[1] === 0
        ? selectedCountry.id[2]
        : idToISO3(selectedCountryId);

    //Regional entities
    const ReginalEntities = ['AMU', 'COMESA', 'CEN-SAD', 'EAC', 'ECCAS', 'ECOWAS', 'IGAD', 'SADC', 'WAEMU']
    if (selectedCountry.id[2]>6){
      filterType = ReginalEntities[selectedCountry.id[2]-7]  //the id start from 7
      countryType = 1
    }
    //Regional entities

    ////////////////////////////////// built-up area 
    function updateBordersVisibility(map, year) {
      // Set all layers to 'none' by default
      const layers = [
        'africapolis-borders-2015',
        'africapolis-borders-2020-1',
        'africapolis-borders-2020-2',
        'africapolis-borders-2020-3',
        'africapolis-borders-2020-4',
        'africapolis-borders-2020-5',
        'africapolis-borders-2025',
        'africapolis-borders-2030',
        'africapolis-borders-2035',
        'africapolis-borders-2040',
        'africapolis-borders-2045',
        'africapolis-borders-2050'
      ];
    
      // Hide all layers first
      layers.forEach(layer => {
        map.setLayoutProperty(layer, 'visibility', 'none');
      });
    
      // Set visibility based on the year
      if (year < 2015) {
        // All layers are already set to 'none', so no further action is required here.
      } else if (year === '2015') {
        map.setLayoutProperty('africapolis-borders-2015', 'visibility', 'visible');
      } else if (year === '2020') {
        map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'visible');
        map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'visible');
        map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'visible');
        map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'visible');
        map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'visible');
      } else if (year === '2025') {
        map.setLayoutProperty('africapolis-borders-2025', 'visibility', 'visible');
      } else if (year === '2030') {
        map.setLayoutProperty('africapolis-borders-2030', 'visibility', 'visible');
      } else if (year === '2035') {
        map.setLayoutProperty('africapolis-borders-2035', 'visibility', 'visible');
      } else if (year === '2040') {
        map.setLayoutProperty('africapolis-borders-2040', 'visibility', 'visible');
      } else if (year === '2045') {
        map.setLayoutProperty('africapolis-borders-2045', 'visibility', 'visible');
      } else if (year === '2050') {
        map.setLayoutProperty('africapolis-borders-2050', 'visibility', 'visible');
        ///

        ///

      }
      // if (year > 2020) {
      //   map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'visible');
      //   map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'visible');
      //   map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'visible');
      //   map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'visible');
      //   map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'visible');
      // }
    }
    updateBordersVisibility(map, year)
//     console.log(year)
//     if (year < 2015) {
//       map.setLayoutProperty('africapolis-borders-2015', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2025', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2030', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2035', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2040', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2045', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2050', 'visibility', 'none');
//     }
//     else if (year === '2015') {

//       map.setLayoutProperty('africapolis-borders-2015', 'visibility', 'visible');
//       map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2025', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2030', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2035', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2040', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2045', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2050', 'visibility', 'none');

//   } else if (year === '2020') {

//       map.setLayoutProperty('africapolis-borders-2015', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'visible');
//       map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'visible');
//       map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'visible');
//       map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'visible');
//       map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'visible'); 
//       map.setLayoutProperty('africapolis-borders-2025', 'visibility', 'none'); 
//       map.setLayoutProperty('africapolis-borders-2030', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2035', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2040', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2045', 'visibility', 'none');
//       map.setLayoutProperty('africapolis-borders-2050', 'visibility', 'none');

//   } else if (year === '2025') {

//     map.setLayoutProperty('africapolis-borders-2015', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2020-1', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2020-2', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2020-3', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2020-4', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2020-5', 'visibility', 'none');
//     map.setLayoutProperty('africapolis-borders-2025', 'visibility', 'visible'); 
// }




    const agglos_class = (filter3) => {
      // if (filter3 > 2020) {filter3 = 2020}
      
      var filterclass = ["any"];
      if (poprange.includes(1)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 10000],
          ["<", "Population_" + filter3, 30000],
        ]);
      }
      if (poprange.includes(2)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 30000],
          ["<", "Population_" + filter3, 100000],
        ]);
      }
      if (poprange.includes(3)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 100000],
          ["<", "Population_" + filter3, 300000],
        ]);
      }
      if (poprange.includes(4)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 300000],
          ["<", "Population_" + filter3, 1000000],
        ]);
      }
      if (poprange.includes(5)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 1000000],
          ["<", "Population_" + filter3, 3000000],
        ]);
      }
      if (poprange.includes(6)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 3000000],
          ["<", "Population_" + filter3, 10000000],
        ]);
      }
      if (poprange.includes(7)) {
        filterclass.push([
          "all",
          [">=", "Population_" + filter3, 10000000],
          ["<", "Population_" + filter3, 1184763600],
        ]);
      }

      return filterclass;
    };

    map.setFilter(
      "agglomerations",
      selectedCountry.id[2] === 6
        ? ["all", agglos_class(year)]
        : ["all", ["==", filterType, countryType], agglos_class(year)]
    );

    map

      
      .setPaintProperty("agglomerations", "circle-color", setAgglosColor(year))
      .setPaintProperty(
        "agglomerations",
        "circle-stroke-color",
        setAgglosColor(year)
      )
      .setPaintProperty(
        "agglomerations",
        "circle-radius",
        setAgglosRadius(year)
      );
  }, [map, selectedCountry, poprange, year]);

  useEffect(() => {}, [query.poprange]);

  // Fly animation for Agglomerations
  useEffect(() => {
    if (!map || !selectedAgglos) return;
    else if (selectedAgglos) {
      map.jumpTo({
        center: [selectedAgglos.lon - 0.14, selectedAgglos.lonlat],
        zoom: 10,
      });
    }
  }, [map, selectedAgglos]);

  // Mouseover for agglomerations
  useEffect(() => {
    if (!map) return;
    map.on("mouseenter", "agglomerations", agglosMouseEnter);
    map.on("mouseleave", "agglomerations", function (e) {
      popUp.remove();
    });
  });

  function agglosMouseEnter(e) {
    map.getCanvas().style.cursor = "pointer";

    let coordinates = e.features[0].geometry.coordinates;
    let selected = e.features[0].properties;
    let year = query ? query.year : 2020;

    popUp
      .setLngLat(coordinates)
      .setHTML(
        `<strong>${selected.Agglomeration_Name}</strong><p>${Math.round(selected[
          `Population_${year}`
        ])
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</p>`
      )
      .addTo(map);
  }

  // Mouse event click for when labels and agglomerations are click to push new query
  useEffect(() => {
    if (!map) return;
    else if (countryReport) {
      map.on("click", "countryLabel", (e) =>
        history.push({
          pathname: `/${locale}/country-report/${e.features[0].properties.NAME_EN}`,
        })
      );
    } else {
      map.on("click", "countryLabel", (e) => {
          pushQuery(history, { country: locale=="fr" ? e.features[0].properties.NAME_FR : e.features[0].properties.NAME_EN });
        })

        .on("click", "regionLabel", (e) =>
          pushQuery(history, { country: locale=="fr" ? e.features[0].properties.Name_FR : e.features[0].properties.Name })
        )
        .on("click", "agglomerations", (e) =>
          handleAgglomerationClick(e, history, query.country)
        );
    }
  }, [history, map, countryReport, locale, query.country]);

  return <div ref={mapContainerRef} className={styles} />;
};

function handleAgglomerationClick(e, h) {
  pushQuery(h, {
    agglomeration: e.features[0].properties.Agglomeration_Name,
  });
}
