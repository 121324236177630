export const countryLabelEN = {
  filter: [
    "all",
    [
      "match",
      ["get", "ISO3_CODE"],
      [
        "AGO",
        "BDI",
        "BEN",
        "BFA",
        "BWA",
        "CAF",
        "CIV",
        "CMR",
        "COD",
        "COG",
        "CPV",
        "DJI",
        "DZA",
        "EGY",
        "ERI",
        "ETH",
        "GAB",
        "GHA",
        "GIN",
        "GMB",
        "GNB",
        "GNQ",
        "KEN",
        "LBR",
        "LBY",
        "LSO",
        "MAR",
        "MLI",
        "MOZ",
        "MRT",
        "MWI",
        "NAM",
        "NER",
        "NGA",
        "RWA",
        "SDN",
        "SEN",
        "SLE",
        "SOM",
        "SSD",
        "STP",
        "SWZ",
        "TCD",
        "TGO",
        "TUN",
        "TZA",
        "UGA",
        "ZAF",
        "ZMB",
        "ZWE",
        "MDG",
        "SYC",
        "COM",
      ],
      true,
      false,
    ],
  ],
  layout: {
    "text-field": ["get", "NAME_EN"],
    "text-font": ["Arial Unicode MS Bold", "Arial Unicode MS Regular"],
  },
  paint: {
    "text-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "hsl(0,0%,0%)",
      "#6d6d6f",
    ],
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.25,
    "text-opacity": ["step", ["zoom"], 0, 3, 1, 22, 1],
  },
};

export const countryLabelFR = {
  filter: [
    "all",
    [
      "match",
      ["get", "ISO3_CODE"],
      [
        "AGO",
        "BDI",
        "BEN",
        "BFA",
        "BWA",
        "CAF",
        "CIV",
        "CMR",
        "COD",
        "COG",
        "CPV",
        "DJI",
        "DZA",
        "EGY",
        "ERI",
        "ETH",
        "GAB",
        "GHA",
        "GIN",
        "GMB",
        "GNB",
        "GNQ",
        "KEN",
        "LBR",
        "LBY",
        "LSO",
        "MAR",
        "MLI",
        "MOZ",
        "MRT",
        "MWI",
        "NAM",
        "NER",
        "NGA",
        "RWA",
        "SDN",
        "SEN",
        "SLE",
        "SOM",
        "SSD",
        "STP",
        "SWZ",
        "TCD",
        "TGO",
        "TUN",
        "TZA",
        "UGA",
        "ZAF",
        "ZMB",
        "ZWE",
        "MDG",
        "SYC",
        "COM",
      ],
      true,
      false,
    ],
  ],
  layout: {
    "text-field": ["get", "NAME_FR"],
    "text-font": ["Arial Unicode MS Bold", "Arial Unicode MS Regular"],
  },
  paint: {
    "text-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "hsl(0,0%,0%)",
      "#6d6d6f",
    ],
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.25,
    "text-opacity": ["step", ["zoom"], 0, 3, 1, 22, 1],
  },
};

export const regionLabelEN = {
  layout: {
    "text-field": ["to-string", ["get", "Name"]],
    "text-font": ["Arial Unicode MS Bold", "Arial Unicode MS Regular"],
    "text-size": 18,
  },
  paint: {
    "text-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "hsl(0,0%,0%)",
      "#6d6d6f",
    ],
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.25,
    "text-opacity": ["step", ["zoom"], 1, 3, 0, 22, 0],
  },
};

export const regionLabelFR = {
  layout: {
    "text-field": ["to-string", ["get", "Name_FR"]],
    "text-font": ["Arial Unicode MS Bold", "Arial Unicode MS Regular"],
    "text-size": 18,
  },
  paint: {
    "text-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "hsl(0,0%,0%)",
      "#6d6d6f",
    ],
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.25,
    "text-opacity": ["step", ["zoom"], 1, 3, 0, 22, 0],
  },
};

export const agglomeration_main = {
  paint: {
    "circle-stroke-width": 1,
    "circle-opacity": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      1,
      0.7,
    ],
  },
};
