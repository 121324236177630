import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { AppLayout } from "./modules/layout";

// import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-NEJJ727XQ0');
ReactGA.set({ page: window.location.pathname });
// ReactGA.pageview(window.location.pathname + window.location.search);

// export const history = createBrowserHistory({
//     basename: process.env.PUBLIC_URL
// });

function App() {
  return (
    // <BrowserRouter basename={'/demo'}>
      <BrowserRouter>
      <Switch>
        <Route path={`/:locale`} component={AppLayout}/>
        {/* <Redirect from="/*" to="/en" /> */}
        <Redirect to="/en" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
