import React from "react";
import classes from "./tooltip.module.css";

export default ({ ...props }) => {
  let payload = props.payload[0] ? props.payload[0].payload : props.payload;
  return (
    <div className={classes.tooltip}>
      {payload[props.type]} <br /> {props.type!="country"? payload.value : payload.value <1 && payload.value >0 ? (payload.value*100).toFixed(2) +"%": payload.value}
    </div>
  );
};
