import { fetchInstance } from '../../axios';

function fetchCountriesDataFail(err) {
	return {
		type: "FETCH_COUNTRIES_DATA_FAIL"
	}
}

export function fetchCountryReportData(locale, country) {
	return dispatch => {
		fetchInstance.get(`./json/countries/${locale}/countries.json`)
			.then(res => {
				dispatch({
					type: "FETCH_COUNTRY_REPORT_DATA",
					data: res.data
				})
			})
			.catch(err => {
				dispatch(fetchCountriesDataFail(err));
			})
	};
}

export function fetchCountryList(locale) {
	return dispatch => {
		fetchInstance.get(`./json/countries/${locale}_country-list.json`)
			.then(res => {
				dispatch({
					type: "FETCH_COUNTRY_LIST",
					data: res.data
				})
			})
			.catch(err => {
				dispatch(fetchCountriesDataFail(err));
			})
	};
}

export function initialFetchCountryData(locale, country) {
	return dispatch => {
		// fetchInstance.get(`/json/countries/explore/${locale}/${country}.json`)
		fetchInstance.get(`./json/countries/${locale}/agglomerations_2020.json`)
			.then(res => {

				console.log(res.data)
				try {
					var parsedData = JSON.parse(res.data);
				} catch (error) {
					console.error("Error parsing JSON: " + error.message);
				}

				dispatch({
					type: "INITIAL_FETCH_EXPLORE_DATA",
					data: res.data
				})
			})
			.catch(err => {
				dispatch(fetchCountriesDataFail(err));
			})
	};
}

export function filterCountryData(data, country) {
	return {
		type: "FILTER_COUNTRY_DATA",
		data: data,
		selectedCountry: country,
	}
}

export function filterAgglosData(data, agglos, country) {
	return {
		type: "FILTER_AGGLOS_DATA",
		data: data,
		selectedAgglos: agglos,
		country: country
	}
}


export function clearCountriesData(locale, country) {
	return {
		type: "CLEAR_COUNTRIES_DATA"
	}
}

export function fetchCountriesData(locale){
	return dispatch => {
		fetchInstance.get(`./json/config/${locale}_countries.json`)
			.then(res => {
				dispatch({
					type: "FETCH_COUNTRIES_DATA",
					data: res.data
				})
			})
			.catch(err => {
				dispatch(fetchCountriesDataFail(err));
			})
	};
}
