import { fetchInstance } from '../../axios';

function fetchMainDataFail(err) {
    return {
        type: "FETCH_MAIN_DATA_FAIL"
    }
}

export function fetchMainData(locale){
    return dispatch => {
        fetchInstance.get(`/json/config/${locale}_main.json`)
            .then(res => {
                dispatch({
                    type: "FETCH_MAIN_DATA",
                    data: res.data
                })
            })
            .catch(err => {
                dispatch(fetchMainDataFail(err));
            })
    };
}