export const popRangeArray = [
  { id: 1, range: [10000, 30000], label: "10 000- 30 000" },
  { id: 2, range: [30000, 100000], label: "30 000 - 100 000" },
  { id: 3, range: [100000, 300000], label: "100 000 - 300 000" },
  { id: 4, range: [300000, 1000000], label: "300 000 - 1M" },
  { id: 5, range: [1000000, 3000000], label: "1-3M" },
  { id: 6, range: [3000000, 10000000], label: "3-10M" },
  { id: 7, range: [10000000, 1184763700], label: "Above 10M" }
];

export const colorsByPopRange = [
  "#FEECB3",
  "#F8DCA0",
  "#F1B78C",
  "#E38479",
  "#CB5260",
  "#A02F3F",
  "#602317",
];

// Convert ID to ISO3
export const isoList = {
  1: "AGO",
  2: "BDI",
  3: "BEN",
  4: "BFA",
  5: "BWA",
  6: "CAF",
  7: "CIV",
  8: "CMR",
  9: "COD",
  10: "COG",
  11: "COM",
  12: "CPV",
  13: "DJI",
  14: "DZA",
  15: "EGY",
  16: "ERI",
  17: "ETH",
  18: "GAB",
  19: "GHA",
  20: "GIN",
  21: "GMB",
  22: "GNB",
  23: "GNQ",
  24: "KEN",
  25: "LBR",
  26: "LBY",
  27: "LSO",
  28: "MAR",
  29: "MDG",
  30: "MLI",
  31: "MOZ",
  32: "MRT",
  33: "MUS",
  34: "MWI",
  35: "MYT",
  36: "NAM",
  37: "NER",
  38: "NGA",
  39: "REU",
  40: "RWA",
  41: "SDN",
  42: "SEN",
  43: "SLE",
  44: "SOM",
  45: "SSD",
  46: "STP",
  47: "SWZ",
  48: "SYC",
  49: "TCD",
  50: "TGO",
  51: "TUN",
  52: "TZA",
  53: "UGA",
  54: "ZAF",
  55: "ZMB",
  56: "ZWE",
};

export const countryNameToISO3= {
  "Afrique du Sud": "ZAF",
  "Algérie": "DZA",
  "Angola": "AGO",
  "Bénin": "BEN",
  "Botswana": "BWA",
  "Burkina Faso": "BFA",
  "Burundi": "BDI",
  "Cabo Verde": "CPV",
  "Cameroun": "CMR",
  "Comores": "COM",
  "Côte d’Ivoire": "CIV",
  "Djibouti": "DJI",
  "Égypte": "EGY",
  "Érythrée": "ERI",
  "Éthiopie": "ETH",
  "Gabon": "GAB",
  "Gambie": "GMB",
  "Ghana": "GHA",
  "Guinée": "GIN",
  "Guinée équatoriale": "GNQ",
  "Guinée-Bissau": "GNB",
  "Kenya": "KEN",
  "Lesotho": "LSO",
  "Libéria": "LBR",
  "Libye": "LBY",
  "Madagascar": "MDG",
  "Malawi": "MWI",
  "Mali": "MLI",
  "Maroc": "MAR",
  "Maurice": "MUS",
  "Mauritanie": "MRT",
  "Mozambique": "MOZ",
  "Namibie": "NAM",
  "Niger": "NER",
  "Nigéria": "NGA",
  "Ouganda": "UGA",
  "République du Congo": "COG",
  "République centrafricaine": "CAF",
  "République démocratique du Congo": "COD",
  "Royaume d’Eswatini": "SWZ",
  "Rwanda": "RWA",
  "Sao Tomé-et-Principe": "STP",
  "Sénégal": "SEN",
  "Seychelles": "SYC",
  "Sierra Leone": "SLE",
  "Somalie": "SOM",
  "Soudan": "SDN",
  "Soudan du Sud": "SSD",
  "Tanzanie": "TZA",
  "Tchad": "TCD",
  "Togo": "TGO",
  "Tunisie": "TUN",
  "Zambie": "ZMB",
  "Zimbabwe": "ZWE",
  "Angola": "AGO",
  "Burundi": "BDI",
  "Benin": "BEN",
  "Burkina Faso": "BFA",
  "Botswana": "BWA",
  "Central African Republic": "CAF",
  "Cote d'Ivoire": "CIV",
  "Cameroon": "CMR",
  "Democratic Republic of the Congo": "COD",
  "Republic of the Congo": "COG",
  "Comoros": "COM",
  "Cabo Verde": "CPV",
  "Djibouti": "DJI",
  "Algeria": "DZA",
  "Egypt": "EGY",
  "Eritrea": "ERI",
  "Ethiopia": "ETH",
  "Gabon": "GAB",
  "Ghana": "GHA",
  "Guinea": "GIN",
  "Gambia": "GMB",
  "Guinea-Bissau": "GNB",
  "Equatorial Guinea": "GNQ",
  "Kenya": "KEN",
  "Liberia": "LBR",
  "Libya": "LBY",
  "Lesotho": "LSO",
  "Morocco": "MAR",
  "Madagascar": "MDG",
  "Mali": "MLI",
  "Mozambique": "MOZ",
  "Mauritania": "MRT",
  "Mauritius": "MUS",
  "Malawi": "MWI",
  "Namibia": "NAM",
  "Niger": "NER",
  "Nigeria": "NGA",
  "Rwanda": "RWA",
  "Sudan": "SDN",
  "Senegal": "SEN",
  "Sierra Leone": "SLE",
  "Somalia": "SOM",
  "South Sudan": "SSD",
  "Sao Tome and Principe": "STP",
  "Kingdom of Eswatini": "SWZ",
  "Seychelles": "SYC",
  "Chad": "TCD",
  "Togo": "TGO",
  "Tunisia": "TUN",
  "Tanzania": "TZA",
  "Uganda": "UGA",
  "South Africa": "ZAF",
  "Zambia": "ZMB",
  "Zimbabwe": "ZWE"
}

export const bBoxById = {
  1: [
    [11.67880883351409, -18.0395441112506],
    [24.0827635146694, -4.377635366107427],
  ],
  2: [
    [28.993800497072044, -4.46551358137126],
    [30.8450390589687, -2.312156363797868],
  ],
  3: [
    [0.774558544224476, 6.22447495853009],
    [3.85082880284835, 12.393508622871479],
  ],
  4: [
    [-5.520794727315028, 9.39964017278909],
    [2.40608025898589, 15.085258417277188],
  ],
  5: [
    [20.001242333380191, -26.8837918049531],
    [29.3618491231722, -17.781364563205884],
  ],
  6: [
    [14.419995451249584, 2.21983656110873],
    [27.4611737379457, 11.009192333734617],
  ],
  7: [
    [-8.569597716425136, 4.35678586749077],
    [-2.49396129202089, 10.734519638596169],
  ],
  8: [
    [8.492690805810334, 1.65404064168932],
    [16.1943512292781, 13.080169051742061],
  ],
  9: [
    [12.206163360999454, -13.4581143630142],
    [31.3065062339817, 5.373053530527471],
  ],
  10: [
    [11.206388845056409, -5.02585440304395],
    [18.6498697204794, 3.703069114856376],
  ],
  11: [
    [43.2656, -11.363295],
    [44.629177, -12.407687],
  ],
  12: [
    [-25.357213543522146, 14.8050880064408],
    [-22.6764804894878, 17.194676527218675],
  ],
  13: [
    [41.771301520025517, 10.910318687075],
    [43.4137973572072, 12.706622709944],
  ],
  14: [
    [-8.673969078782633, 18.9635345895493],
    [11.9809701071419, 37.09150628917579],
  ],
  15: [
    [24.697996257418708, 22.0013421472637],
    [35.7615737570691, 31.664110210683646],
  ],
  16: [
    [36.437281372495079, 12.3605223286841],
    [43.1336269463708, 18.002201465540395],
  ],
  17: [
    [32.998438894493276, 3.4009078412285],
    [47.9842742919989, 14.892966221704711],
  ],
  18: [
    [8.695935814827443, -3.97664306377109],
    [14.5024185687666, 2.324250541417371],
  ],
  19: [
    [-3.257547122710676, 4.73578730703557],
    [1.19206530262996, 11.173995950817698],
  ],
  20: [
    [-15.079191428377445, 7.20230122963262],
    [-7.64122957762785, 12.673679033707842],
  ],
  21: [
    [-16.826079936892199, 13.0527230912037],
    [-13.7937487859001, 13.827261735338197],
  ],
  22: [
    [-16.716210858836821, 10.9267692162175],
    [-13.6344428845149, 12.684674465103612],
  ],
  23: [
    [5.603193718085414, -1.46069231784424],
    [11.3327510702054, 3.785449614422333],
  ],
  24: [
    [33.910335195172479, -4.67975402178414],
    [41.8976211272234, 4.620420513282312],
  ],
  25: [
    [-11.492059789361775, 4.35124553384174],
    [-7.36655688248939, 8.553673842533343],
  ],
  26: [
    [9.388093959395881, 19.5073822641284],
    [25.148468001036, 33.169291009271674],
  ],
  27: [
    [27.027195141422098, -30.6577392328189],
    [29.4662204855297, -28.570269367717884],
  ],
  28: [
    [-13.167520611755194, 27.6594718132624],
    [-0.994299518106317, 35.910435009056016],
  ],
  29: [
    [43.957916, -11.233853],
    [49.644682, -26.453862],
  ],
  30: [
    [-12.233633448284422, 10.1632260034789],
    [4.24084698276459, 25.000708313044015],
  ],
  31: [
    [30.218810884823739, -26.8673412758106],
    [40.8374143565547, -10.475241344327628],
  ],
  32: [
    [-17.067787646818925, 14.7171671732258],
    [-4.82863658274659, 27.296920902860109],
  ],
  33: [
    [57.310283, -19.802283],
    [57.962243, -20.549742],
  ],
  34: [
    [32.679827091722899, -17.1221500948736],
    [35.9153819427564, -9.371052848075948],
  ],
  35: [
    [44.945661, -12.58412],
    [45.324563, -13.007537],
  ],
  36: [
    [11.717292843399271, -28.9713042880053],
    [25.2583370790913, -16.957389095741675],
  ],
  37: [
    [0.164802208197493, 11.6958527626051],
    [15.9966039359589, 23.523016092945412],
  ],
  38: [
    [2.669757522728581, 4.27986046567154],
    [14.6781749992943, 13.893191705761652],
  ],
  39: [
    [55.138597, -20.831089],
    [55.961286, -21.430086],
  ],
  40: [
    [28.856485458478389, -2.83951089128324],
    [30.8944758822985, -1.054159681858877],
  ],
  41: [
    [21.841485463881099, 8.68549116542911],
    [38.5796857766238, 22.226578019072377],
  ],
  42: [
    [-17.518238081460641, 12.3056304076076],
    [-11.3437280103967, 16.694767960271577],
  ],
  43: [
    [-13.310333366046727, 6.92762853449416],
    [-10.2835212397281, 9.998422386395802],
  ],
  44: [
    [40.991222542242099, -1.66389470891022],
    [51.4120787206538, 11.97602317344149],
  ],
  45: [
    [24.154191200790834, 3.48882867444349],
    [35.9483682369437, 12.199554327204311],
  ],
  46: [
    [6.471150911132696, 0.027995333650139],
    [7.46542771137786, 1.70347746501912],
  ],
  47: [
    [30.79560223563891, -27.3178130194279],
    [32.1359794171438, -25.719213671927008],
  ],
  48: [
    [55.093678, -4.172116],
    [55.993866, -4.88018],
  ],
  49: [
    [13.475155474334223, 7.43853253283703],
    [24.0003830151035, 23.451588406824072],
  ],
  50: [
    [-0.148311878875006, 6.10365306702988],
    [1.79632392295911, 11.141009656630501],
  ],
  51: [
    [7.52585996610344, 30.2413525296127],
    [11.5964283339481, 37.349728455171743],
  ],
  52: [
    [29.328905446936076, -11.7441908397112],
    [40.4419197699163, -0.988272329386561],
  ],
  53: [
    [29.5760895635851, -1.48264056268463],
    [35.0364719362645, 4.213930495248093],
  ],
  54: [
    [16.458028493020869, -46.9784114781806],
    [38.0028518078575, -22.126605626189018],
  ],
  55: [
    [22.000791365266281, -18.0779855031847],
    [33.6850993233638, -8.222967862143477],
  ],
  56: [
    [25.23634621629985, -22.4177288504699],
    [33.0588711492188, -15.611514198538828],
  ],


  // Regions
  57: [
    [3.431850214431101, 24.109042720933672],
        [34.998053942265194, -14.133610970121568]
  ],
  58: [[18.04921664310602,22.804899652984354],
    [60.333536732896945,-26.513372676941824]
  ],
  59: [
    [ -31.776582980530517, 39.50430811821969],
    [37.46617765819011, 8.452060095991035],
  ],
  60: [
    [-4.179512904198239, -4.276446306615885],
    [54.20400004763876, -36.778824721575226],
  ],
  61: [
    [-40.92211283079332,20.76647427916762],
    [26.115548212276998, -5.490641035879767],
  ],
};
