import { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import {
  addSources,
  addLayers,
  onMouseHover,
} from "../../modules/visualisations/components/MapComponents/mapFunctions";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWttZCIsImEiOiJjajBqYjJpY2owMDE0Mndsbml0d2V1ZXczIn0.el8wQmA-TSJp2ggX8fJ1rA";

const useMap = (config, layer, buildUpYear, locale) => {
  const [map, setMap] = useState(null);
  const [popUp, setPopUp] = useState(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    let mounted = true;
    // if (!map) {
      // Set mapContainerRef as container and return
      const mapConfig = config.mapConfig ? config.mapConfig : "";
      let linkOfLayer = (layer == "base" && buildUpYear===2015) ? "mapbox://styles/mkmd/ck9eg99c72gwg1imtdbugc9yn"
          : (layer == "nightlight" && buildUpYear===2015) ? "mapbox://styles/mkmd/cldcyxeo5006d01p5q0elhc53"
              : (layer == "satellite"&& buildUpYear===2015) ? "mapbox://styles/mkmd/ck8zz7o2q0vt41ipgy9mlgp0t"

              :(layer == "base" && buildUpYear===2020) ? "mapbox://styles/mkmd/clixn7s2500hd01qyao1kduk4"
                  : (layer == "nightlight" && buildUpYear===2020) ? "mapbox://styles/mkmd/clj1kbuct01a501qvb2ch68qc"
                      : (layer == "satellite"&& buildUpYear===2020) ? "mapbox://styles/mkmd/clj1kjxvw00e601pbdjygfxgg"
      : "mapbox://styles/mkmd/ck9eg99c72gwg1imtdbugc9yn"

      const map = new mapboxgl.Map({
        ...mapConfig,
        container: mapContainerRef.current,
        style: linkOfLayer, // to change the tileset through mapbox $$$$
        center: mapConfig.center || [2.111, 14.72],
        zoom: 2.5,
      });

      const popUp = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: "borderpoint",
      });

      map.on("load", () => {
        if (!config) return;
        // set map and popUp as State and return
        setPopUp(popUp);
        setMap(map);
        // Loop through all sources and add.
        addSources(config.tiles, map);
        // Loop through all Layers and add
        addLayers(config.tiles, map);
        // resize the map to the container
        map.resize();
      });
      // :MOUSEOVER & MOUSELEAVE: Loop through all mouse actions defined in config and add
      onMouseHover(config.tiles, map, mouseEnter, mouseLeave);

      let hoveredId = null;
      function mouseEnter(e, source, sourceLayer) {
        map.getCanvas().style.cursor = "pointer";
        if (e.features.length > 0) {
          if (hoveredId) {
            map.setFeatureState(
              { source: source, id: hoveredId, sourceLayer: sourceLayer },
              { hover: false }
            );
          }
          hoveredId = e.features[0].id;
          map.setFeatureState(
            { source: source, id: hoveredId, sourceLayer: sourceLayer },
            { hover: true }
          );
        }
      }

      function mouseLeave(source, sourceLayer) {
        map.getCanvas().style.cursor = "";
        if (hoveredId) {
          map.setFeatureState(
            { source: source, id: hoveredId, sourceLayer: sourceLayer },
            { hover: false }
          );
        }
        // agglosPopup.remove();
        hoveredId = null;
      }
    // }

    return () => {
      // mounted = false;
      // if (mounted)
        map.remove();
    };
  }, [layer, buildUpYear]);

  return {
    map,
    mapContainerRef,
    popUp,
  };
};

export default useMap;
