const initialState = {
    data: {},
    countryData: [],
    agglosData: [],
    selectedAgglos: []
};

function fetchInitialExploreData( state, { selectedCountry, data } ) {

	return {
		...state,
		countryData: data.country,
		agglosData: data.agglomeration,
		// selectedCountry: filterCountries(data.country, selectedCountry, 'title'),
		// selectedAgglos: null
	}
}


export function reducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_DATA_DATA": return { ...state, data: action.data };
      case "FETCH_DATA_DATA_FAIL": return { ...state };
      case "FETCH_COUNTRIES_DATA_FAIL": return { ...state };
      case "INITIAL_FETCH_EXPLORE_DATA": return fetchInitialExploreData(state, action); //return { ...state, explore: action.data };
      default:
        return state;
    }
}
