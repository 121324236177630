import React from "react";
import { Localiser } from "../../../static";
import { LinkItem } from "../index";
// import { Navigation } from "../../navigation";
import classes from "./css/HeaderItems.module.css";

export default (props) => {
  const { mouseOver, data, locale, pathname } = props;  
  return (
    <ul className={classes.HeaderItems}>
      {data.nav.map((d, idx) => {
        let linkStyle = classes.Item;
        if (pathname.length > 3)
          linkStyle = [classes.Item, classes.dark].join(" ");
        if (mouseOver === d.id)
          linkStyle = [linkStyle, classes.active].join(" ");
        let url = `/${locale}/${d.url}`;
        if (d.url === "data") {
          url = {
            pathname: `/${locale}/${d.url}`,
            search: `?country=Angola&keyfigure=totalPop&type=abs&year=2015`,
          };
        }
        return (
          <li
            key={idx}
            className={classes.HeaderItem}
            onMouseEnter={() => props.toggleHover(d.id)}
            onMouseLeave={props.toggleLeave}
          >
            <LinkItem
              type="nav"
              url={url}
              style={linkStyle}
              exact={d.exact}
              item={d.item}
            />
          </li>
        );
      })}
      <Localiser.Main
        data={data.i18nSelector}
        pathname={pathname}
        mouseOver={mouseOver}
      />
    </ul>
  );
};
