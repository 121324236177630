import React from "react";
import { TextLink } from "../../static/index";
// import classes from './css/Article.module.css';

export default ({ item, links, subtitle }) => {
  let tempLinks = {};
  for (let item in links) tempLinks[links[item].match] = links[item].link;
  return (
    <>
      {subtitle ? <h5>{subtitle}</h5> : null}
      <TextLink text={item} links={tempLinks} />
    </>
  );
};
