import React from "react";
import { pushQuery } from "../../global";
import { BarChart, Cell, Bar, Tooltip } from "recharts";
import { tooltip } from "../../static";

export default ({ type, history, bardata, query }) => {
  let width = 260;
  let height = 65;
  let style =
    type === "country"
      ? {
          dataKey: "value",
          fill: "#e8ae40",
          id: "country",
        }
      : {
          dataKey: "value",
          fill: "#c3533e",
          id: "agglomeration",
        };
  return (
    <BarChart width={width} height={height} data={bardata.data}>
      <Bar
        dataKey={style.dataKey}
        minPointSize={3}
        id={style.id}
        onClick={(d) => pushQuery(history, { [type]: d[type] })}
      >
        {bardata.data.map((entry, index) => (
          <Cell
            cursor="pointer"
            key={`cell-${index}`}
            fill={entry[type] === query[type] ? style.fill : "#e0e0e0"}
            stroke={entry[type] === query[type] ? style.fill : "none"}
            strikeWidth={entry[type] === query[type] ? 1 : 0}
          />
        ))}
      </Bar>
      <Tooltip content={tooltip} length={bardata.data.length} type={type} />
    </BarChart>
  );
};
