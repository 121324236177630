import React from "react";
import { KeyFigureComponents } from "../index";
import classes from "./css/KeyFigure.module.css";

export default ({ data }) => {
  return (
    <div className={classes.KeyFigureWrapper}>
      {data.map((d, idx) => (
        <KeyFigureComponents
          key={`${d.title}-${idx}`}
          title={d.title}
          type={d.type}
          value={d.value}
        />
      ))}
    </div>
  );
};
