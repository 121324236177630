const initialState = {
  data: {},
  countryReport: {},
  countryList: {},
  selectedCountry: {},
  selectedAgglos: {},
  countryData: [],
  agglosData: [],
};

function filterCountries(data, selected, name) {

  return selected === "" ? null : data.filter((d) => d[name] === selected)[0];
}

function filterAgglos(data, selected, country) {
  console.log(country)
  if(country) return selected === "" ? null : data.filter((d) => d["name"] === selected && d["ISO3"]== country)[0]
  else return selected === "" ? null : data.filter((d) => d["name"]=== selected)[0];
}

function fetchInitialExploreData(state, { selectedCountry, data }) {

  return {
    ...state,
    countryData: data.country,
    agglosData: data.agglomeration,
    // selectedCountry: filterCountries(data.country, selectedCountry, 'title'),
    // selectedAgglos: null,
  };
}

function filterCountryData(state, { data, selectedCountry }) {
  if (data.length === 0) return { ...state };
  return {
    ...state,
    selectedCountry: filterCountries(data, selectedCountry, "title"),
    // countryReport: filterCountries(data, selectedCountry, 'title')
  };
}

function filterAgglosData(state, { data, selectedAgglos, country }) {
  return {
    ...state,
    selectedAgglos: filterAgglos(data, selectedAgglos, country),
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_COUNTRIES_DATA":
      return { ...state, data: action.data };
    case "FETCH_COUNTRIES_DATA_FAIL":
      return { ...state };
    case "FETCH_COUNTRY_REPORT_DATA":
      return { ...state, countryReport: action.data };
    case "FETCH_COUNTRY_LIST":
      return { ...state, countryList: action.data };
    case "INITIAL_FETCH_EXPLORE_DATA":
      return fetchInitialExploreData(state, action); //return { ...state, explore: action.data };
    case "FILTER_COUNTRY_DATA":
      return filterCountryData(state, action);
    case "FILTER_AGGLOS_DATA":
      return filterAgglosData(state, action);
    case "CLEAR_COUNTRIES_DATA":
      return {
        ...state,
        data: {},
        selectedCountry: {},
        countryReport: {},
        countryList: {},
      };
    default:
      return state;
  }
}
