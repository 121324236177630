import React from "react";
import { Headers } from "../../headers/header";
import { Footer } from "../../footer";

export const WithNav = (props) => (
  <>
    <Headers {...props} />
    {props.children}
    <Footer {...props} />
  </>
);

export const WithoutNav = (props) => props.children;
