import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Container } from "../../../containers";
import { Layout } from "../index";
import queryString from "query-string";
// import { ScrollToTop } from "../../static/index";
import * as actions from "../../../store/actions/index";

function AppLayout(props) {
  const match = props.match.url,
    path = props.match.path;
  const dispatch = useDispatch();
  const baseURL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV
      : process.env.REACT_APP_PROD;
  
  const i18n = useSelector((state) => state.i18n);
  const routes = [
    {
      ID: 2,
      hoc: Layout.WithNav,
      component: Container.Countries,
      url: "/countries",
      exact: false,
      ignoreScrollBehavior: true,
    },
    {
      ID: 3,
      hoc: Layout.WithoutNav,
      component: Container.Explore,
      url: "/explore",
      exact: false,
      ignoreScrollBehavior: false,
    },
    {
      ID: 4,
      hoc: Layout.WithoutNav,
      component: Container.CountryReport,
      url: "/country-report/:country",
      exact: false,
      ignoreScrollBehavior: false,
    },
    {
      ID: 5,
      hoc: Layout.WithNav,
      component: Container.Research,
      url: "/research",
      exact: false,
      ignoreScrollBehavior: false,
    },
    {
      ID: 6,
      hoc: Layout.WithNav,
      component: Container.Data,
      url: "/data",
      exact: false,
      ignoreScrollBehavior: false,
    },
    {
      ID: 7,
      hoc: Layout.WithNav,
      component: Container.About,
      url: "/about",
      exact: false,
      ignoreScrollBehavior: false,
    },
    {
      ID: 1,
      hoc: Layout.WithNav,
      component: Container.Main,
      url: "/",
      exact: true,
    },
  ];

  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchI18nData(match));
  }, [match, dispatch]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);

  //<ScrollToTop />
  if (Object.keys(i18n.data).length === 0) {
    return <div></div>;
  } else {
    return (
      <>
        <Switch>
          {routes.map(({ ID, hoc: Wrapper, component: C, url, exact }) => (
            console.log(`${path}${url}`) ||
            <Route
              key={ID}
              path={`${path}${url}`}
              exact={exact}
              render={({ location, history, match }) => (
                <Wrapper {...props} i18n={i18n.data} baseURL={baseURL}>
                  <C
                    i18n={i18n.data}
                    baseURL={baseURL}
                    q={getParams(location)}
                    history={history}
                    location={location}
                    match={match}
                    ignoreScrollBehavior
                  />
                </Wrapper>
              )}
            />
          ))}
        </Switch>
      </>
    );
  }
}

export default AppLayout;

function getParams(location) {
  return queryString.parse(location.search);
}
