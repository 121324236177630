import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import classes from "../../../containers/components/css/Urbandynamics.module.css";

export default ({ data, country, region, regionTitle, africa, locale }) => {
  const years = {
    1950: 0,
    1960: 1,
    1970: 2,
    1980: 3,
    1990: 4,
    2000: 5,
    2010: 6,
    2015: 7,
    2020: 8,
    2025: 9,
    2030: 10,
    2035: 11,
    2040: 12,
    2045: 13,
    2050: 14
  };

  let data_line = [];

  data.forEach((i, j) =>
    data_line.push({
      id: "Total population",
      year: Number(Object.keys(years)[j]),
      [country]: (i / data[4]) * 100,
      [regionTitle]: (region[j] / region[4]) * 100,
      Africa: (africa[j] / africa[4]) * 100,
    })
  );

  // divise data_line to 'before2020' and 'after2020' to apply different styles
  const dataBefore2025 = data_line.filter((d) => d.year <= 2020);
  const dataAfter2025 = data_line.filter((d) => d.year >= 2020);

  const renderTooltip = (props) => {
    if (props.active && props.payload !== null && props.payload[0] !== null) {
      let payload = props.payload[0].payload;
      let tooltip = null;

      tooltip = (
        <div className={classes.Tooltip}>
          <p style={{ marginLeft: "10px", marginRight: "10px" }}>
            {payload.year} <small>(Index 1990 = 100x)</small>{" "}
          </p>
          {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value)}x
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
          {/* {props.payload.map((i, idx) => (
            <p
              key={idx}
              style={{
                color: i.color,
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {i.dataKey}: {Math.round(i.value)}x
            </p>
          ))} */}
        </div>
      );
      return tooltip
    }
    return null;
  };

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer height={400} width="100%">
        <LineChart
          height={400}
          data={data_line}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            dataKey="year"
            domain={[1950, 2050]}
            type="number"
            ticks={[1950, 1960, 1970, 1980, 1990, 2000, 2010, 2015, 2020, 2025, 2030, 2035, 2040, 2045, 2050]}
          />
          {/* <YAxis type='number'  strokeWidth="0px" stroke='black' tick={{fontSize: '11px'}} /> */}
          {/* <Tooltip /> */}
          <Tooltip
            cursor={{ strokeWidth: 0 }}
            content={renderTooltip}
            type={data.ID}
            language={locale}
          />
          { /* Country-specific lines */ }
          <React.Fragment>
            <Line
              dataKey={country}
              key={`${country}-solid`}
              data={dataBefore2025}
              // data={data.filter(d => d.year < 2025)}
              stroke="#c5543e"
              strokeWidth={2}
            />
            <Line
              dataKey={country}
              legendType="none"
              key={`${country}-dashed`}
              data={dataAfter2025}
              // data={data.filter(d => d.year >= 2020)}
              stroke="#c5543e"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ strokeDasharray: '' }}
              connectNulls={true}
            />
          </React.Fragment>
          { /* Region-specific lines */ }
            <Line
              dataKey={regionTitle}
              key={`${regionTitle}-solid`}
              data={dataBefore2025}
              stroke="#6791cc"
              strokeWidth={2}
            />
            <Line
              dataKey={regionTitle}
              legendType="none"
              key={`${regionTitle}-dashed`}
              data={dataAfter2025}
              stroke="#6791cc"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ strokeDasharray: '' }}
              connectNulls={true}
            />

        { /* Africa-specific lines */ }
            <Line
              dataKey="Africa"
              key="Africa-solid"
              data={dataBefore2025}
              stroke="#aad7a7"
              strokeWidth={2}
            />
            <Line
              dataKey="Africa"
              legendType="none"
              key="Africa-dashed"
              data={dataAfter2025}
              stroke="#aad7a7"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ strokeDasharray: '' }}
              connectNulls={true}
            />


          {/* <Line
            type="monotone"
            dataKey={country}
            stroke="#c5543e"
            strokeWidth={2} 
          />
          <Line
            type="monotone"
            dataKey={regionTitle}
            stroke="#6791cc"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="Africa"
            stroke="#aad7a7"
            strokeWidth={2}
          /> */}
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
