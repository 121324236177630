import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout/index";
import { Article } from "./index";
import { Grid, GridWrapper, GridContainer } from "../../modules/grids/index";
import { Route, Switch } from "react-router-dom";
import * as actions from "../../store/actions/index";
import classes from "./css/Research.module.css";

export default (props) => {
  const { url, path } = props.match,
    { locale } = props.match.params;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.research.data);

  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchResearchData(locale));
  }, [locale, dispatch]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);

  const fetchData = {
    fetch: (m, s) => actions.fetchStoryData(m, s),
    clear: () => actions.clearStoryData(),
  };

  if (Object.keys(data).length === 0) {
    return null;
  } else {
    return (
      <Switch>
        <Route path={path} exact>
          <ContainerType.Text {...props} data={data} border>
            <GridWrapper>
              <GridContainer
                  style={classes.Container}
                  header
                  title={data.dataResearch.title}
                  type="twelve"
              >
                <Grid.Research
                    data={data.dataResearch.list}
                    match={url}
                    style={classes.ResearchItem2}
                />
              </GridContainer>
              <GridContainer
                style={classes.Container}
                header
                title={data.dataDrivenStories.title}
                type="twelve"
              >
                <Grid.Research
                  data={data.dataDrivenStories.list}
                  match={url}
                  style={classes.ResearchItem}
                />
              </GridContainer>
            </GridWrapper>
          </ContainerType.Text>
        </Route>
        <Route
          path={`${path}/:story`}
          exact
          render={(props) => (
            <Article {...props} fetchData={fetchData} parent="research" />
          )}
        />
      </Switch>
    );
  }
};
