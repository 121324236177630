import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArticleLayout } from "../../modules/layout/index";
import { Article } from "../../modules/article/index";
import classes from "./css/Article.module.css";
import showdown from "showdown";

export default (props) => {
  const { story, locale } = props.match.params;
  const parent = props.parent;
  const fetchData = props.fetchData;

  const dispatch = useDispatch();
  const data = useSelector((state) => state[parent].story);

  const stableDispatch = useCallback(() => {
    dispatch(fetchData.fetch(locale, story));
  }, [locale, story, fetchData, dispatch]);

//markdown plugin

  const conv = new showdown.Converter();

  conv.setOption('tables', true);
///markdown plugin end


  useEffect(() => {
    stableDispatch();
    return () => {
      // Make proper clearing function
      dispatch(fetchData.clear());
    };
  }, [stableDispatch, dispatch, fetchData]);


  if (Object.keys(data).length === 0) {
    return <div></div>;
  }
  //markdown plugin
  else if(data.markdown!=null){
    return (
        <ArticleLayout title={data.title}>
          <div className={classes.MarkdownText}>
            <div dangerouslySetInnerHTML={{ __html: conv.makeHtml(data.markdown) }} />
          </div>
        </ArticleLayout>
    );
  }
  //end markdown plugin
  else {
    return (
      <ArticleLayout title={data.title}>
        {data.list.map((d, idx) => {
          switch (d.type) {
            case "text":
              return (
                <div key={`text-${idx}`} className={classes.ArticleText}>
                  <Article.Text {...d} />
                </div>
              );
            case "chart":
              return (
                <div key={`chart-${idx}`} className={classes.ArticleChart}>
                  <Article.Chart d={d} />
                </div>
              );
            case "image":
              return (
                <div key={`image-${idx}`} className={classes.ArticleImage}>
                  <div className={classes.image__inner}>
                    <img src={`/images/tempimages/${d.url}.png`} alt="" />
                  </div>
                </div>
              );
            case "author":
              return (
                <div key={`author-${idx}`} className={classes.ArticleAuthor}>
                  <span>{d.item}</span>
                </div>
              );
            default:
              return null;
          }
        })}
      </ArticleLayout>
    );
  }
};
