import { fetchInstance } from "../../axios";

function fetchAboutDataFail(err) {
  return {
    type: "FETCH_ABOUT_DATA_FAIL",
  };
}

export function fetchNarrativeData(locale, narrative) {
  return (dispatch) => {
    fetchInstance
      .get(`/json/narratives/${locale}_${narrative}.json`)
      .then((res) => {
        dispatch({
          type: "FETCH_NARRATIVE_DATA",
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch(fetchAboutDataFail(err));
      });
  };
}

export function clearNarrativeData() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_NARRATIVE_DATA",
    });
  };
}

export function fetchAboutData(locale) {
  return (dispatch) => {
    fetchInstance
      .get(`/json/config/${locale}_about.json`)
      .then((res) => {
        dispatch({
          type: "FETCH_ABOUT_DATA",
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch(fetchAboutDataFail(err));
      });
  };
}
