import React from "react";
import classes from "./Visualisation.module.css";
import LineGraph from "./LineGraph/LineGraph";
import { pushQuery } from "../../../../global";
import { TimeSlider } from "../../../../selectors";
import Treemap from "../../Treemap";

const visualisation = (props) => {
  const {
    // language,
    data,
    query,
    history,
    countryListR,
    agglosData,
    language
  } = props;

  const { keyfigure, type } = query;
  const isoList = language==="en"? {
    AGO: "Angola",
    BDI: "Burundi",
    BEN: "Benin",
    BFA: "Burkina Faso",
    BWA: "Botswana",
    CAF: "Central African Republic",
    CIV: "Cote d'Ivoire",
    CMR: "Cameroon",
    COD: "Democratic Republic of the Congo",
    COG: "Republic of the Congo",
    COM: "Comoros",
    CPV: "Cabo Verde",
    DJI: "Djibouti",
    DZA: "Algeria",
    EGY: "Egypt",
    ERI: "Eritrea",
    ETH: "Ethiopia",
    GAB: "Gabon",
    GHA: "Ghana",
    GIN: "Guinea",
    GMB: "The Gambia",
    GNB: "Guinea-Bissau",
    GNQ: "Equatorial Guinea",
    KEN: "Kenya",
    LBR: "Liberia",
    LBY: "Libya",
    LSO: "Lesotho",
    MAR: "Morocco",
    MDG: "Madagascar",
    MLI: "Mali",
    MOZ: "Mozambique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MWI: "Malawi",
    MYT: "Mayotte",
    NAM: "Namibia",
    NER: "Niger",
    NGA: "Nigeria",
    REU: "Réunion",
    RWA: "Rwanda",
    SDN: "Sudan",
    SEN: "Senegal",
    SLE: "Sierra Leone",
    SOM: "Somalia",
    SSD: "South Sudan",
    STP: "Sao Tome and Principe",
    SWZ: "Kingdom of Eswatini",
    SYC: "Seychelles",
    TCD: "Chad",
    TGO: "Togo",
    TUN: "Tunisia",
    TZA: "Tanzania",
    UGA: "Uganda",
    ZAF: "South Africa",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
  } : {
      "ZAF": "Afrique du Sud",
      "DZA": "Algérie",
      "AGO": "Angola",
      "BEN": "Bénin",
      "BWA": "Botswana",
      "BFA": "Burkina Faso",
      "BDI": "Burundi",
      "CPV": "Cabo Verde",
      "CMR": "Cameroun",
      "COM": "Comores",
      "CIV": "Côte d’Ivoire",
      "DJI": "Djibouti",
      "EGY": "Égypte",
      "ERI": "Érythrée",
      "ETH": "Éthiopie",
      "GAB": "Gabon",
      "GMB": "Gambie",
      "GHA": "Ghana",
      "GIN": "Guinée",
      "GNQ": "Guinée équatoriale",
      "GNB": "Guinée-Bissau",
      "KEN": "Kenya",
      "LSO": "Lesotho",
      "LBR": "Libéria",
      "LBY": "Libye",
      "MDG": "Madagascar",
      "MWI": "Malawi",
      "MLI": "Mali",
      "MAR": "Maroc",
      "MUS": "Maurice",
      "MRT": "Mauritanie",
      "MOZ": "Mozambique",
      "NAM": "Namibie",
      "NER": "Niger",
      "NGA": "Nigéria",
      "UGA": "Ouganda",
      "COG": "République du Congo",
      "CAF": "République centrafricaine",
      "COD": "République démocratique du Congo",
      "SWZ": "Royaume d’Eswatini",
      "RWA": "Rwanda",
      "STP": "Sao Tomé-et-Principe",
      "SEN": "Sénégal",
      "SYC": "Seychelles",
      "SLE": "Sierra Leone",
      "SOM": "Somalie",
      "SDN": "Soudan",
      "SSD": "Soudan du Sud",
      "TZA": "Tanzanie",
      "TCD": "Tchad",
      "TGO": "Togo",
      "TUN": "Tunisie",
      "ZMB": "Zambie",
      "ZWE": "Zimbabwe"
    }


  const years = {
    1950: 0,
    1960: 1,
    1970: 2,
    1980: 3,
    1990: 4,
    2000: 5,
    2010: 6,
    2015: 7,
    2020: 8,
    2025: 9,
    2030: 10,
    2035: 11,
    2040: 12,
    2045: 13,
    2050: 14
      
  };

  let viewTypes = language==="en"? [
    { value: "abs", label: "Population in millions" },
    { value: "baseYear", label: "Index 1990 = 100" },
    { value: "growth", label: "Growth rate" },
  ] : [
    { value: "abs", label: "Population (en millions)" },
    { value: "baseYear", label: "Indice 1990 = 100" },
    { value: "growth", label: "Taux de croissance" },
  ]


  let newQuery = query.country.split(",");
  let dataTreemap = [];
  console.log(agglosData)
  agglosData
    .filter((d) => newQuery.includes(isoList[d.ISO3]))
    .forEach((d) =>
      dataTreemap.push({
        name: d.name,
        value: d.uPop[years[query.year]],
        country: isoList[d.ISO3],
      })
    );
  dataTreemap.forEach((e) => {
    if (e.value < 30000) {
      e.group = "10 000 - 30 000";
    } else if (e.value < 100000) {
      e.group = "30 000 - 100 000";
    } else if (e.value < 300000) {
      e.group = "100 000 - 300 000";
    } else if (e.value < 1000000) {
      e.group = "300 000 - 1M";
    } else if (e.value < 3000000) {
      e.group = "1M - 3M";
    } else if (e.value < 10000000) {
      e.group = "3M - 10M";        
    } else {
      e.group = "Above 10M";
    }
  });
  const methods = {
    groupBy:
      query.country.split(",").length > 1
        ? ["country", "group"]
        : ["group", "name"],
    size: (d) => d.value,
    direction: (a, b) => b.group - a.group,
    data: dataTreemap,
    resize: true,
  };

  function comparator(a, b) {
    return b.group - a.group;
  }

  const disabled = ["totalPop", "urbanPop", "ruralPop"].includes(keyfigure);
  const chart =
    query.keyfigure === "Upop" ? (
      <Treemap height={600} config={methods} legendSort={comparator} />
    ) : (
      <LineGraph data={data} query={query} countryListR={countryListR} />
    );

  return (
    <div className={classes.visualisation__inner}>
      <div className={classes.viewType}>

          {query.keyfigure === "Upop" && <TimeSlider
          label={keyfigure === "Upop"}
          disabled={keyfigure !== "Upop"}
          query={query}
          history={history}
        />}

        <div>
          {viewTypes.map((d) => (
            disabled ?  <button
              disabled={disabled ? false : true}
              key={d.value}
              className={
                d.value === type
                  ? [classes.viewType__item, classes.selected].join(" ")
                  : classes.viewType__item
              }
              onClick={() => pushQuery(history, { type: d.value })}
            >
              {d.label}
            </button> : <></>
          ))}
        </div>
      </div>
      {chart}
    </div>
  );
};

export default visualisation;
