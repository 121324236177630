import { fetchInstance } from '../../axios';

function fetchDataDataFail(err) {
    return {
        type: "FETCH_DATA_DATA_FAIL"
    }
}

export function fetchDataData(locale){
    return dispatch => {
        fetchInstance.get(`/json/config/${locale}_data.json`)
            .then(res => {
                dispatch({
                    type: "FETCH_DATA_DATA",
                    data: res.data
                })
            })
            .catch(err => {
                dispatch(fetchDataDataFail(err));
            })
    };
}
