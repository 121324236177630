import React from "react";
import { useMap } from "../../../customHooks";
import classes from "./css/ArticleMap.module.css";
const ArticleMap = () => {
  const config = {
    mapConfig: {},
    tiles: [
      {
        id: "agglomerations",
        source: "africapolis_agglos",
        sourceLayer: "africapolis2020-clhcur",
        type: "vector",
        layerType: "circle",
        url: "mapbox://mkmd.3nrl0izs",
        mouseEvent: false,
      },
    ],
  };
  const { mapContainerRef } = useMap(config);
  return <div ref={mapContainerRef} className={classes.article_map__wrapper} />;
};

export default ArticleMap;
