import React from "react";
import classes from "./css/GridComponents.module.css";
export default ({ style, header, type, seeAll, title, ...props }) => {
  return (
    <div className={[style, "container"].join(" ")}>
      {header && (
        <header
          className={[classes.GridHeader, classes[`GridHeader_${type}`]].join(
            " "
          )}
        >
          <h3>{title}</h3>
        </header>
      )}
      {props.children}
    </div>
  );
};
