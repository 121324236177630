import React from "react";
import { LinkItem } from "../../header/index";
import { Localiser } from "../../../static";
// import { toURLString } from "../../../global";
import classes from "./css/MobileHeaderItems.module.css";

export default (props) => {
  return (
    <div className={classes.Mobile_HeaderList}>
      {/* <div className={style}> */}
      <ul className={classes.Mobile_NavItems}>
        <li className={classes.Mobile_Item}>
          {props.locale==="en" ? <LinkItem
            type="main_nav_item"
            url={`/${props.locale}`}
            style={classes.Mobile_Item_Link}
            exact={false}
            item="Home"
          /> : <LinkItem
          type="main_nav_item"
          url={`/${props.locale}`}
          style={classes.Mobile_Item_Link}
          exact={false}
          item="Accueil"
        />}
        </li>
        {props.data.nav.map((d, idx) => {
          let url = `/${props.locale}/${d.url}`;
          if (d.url === "data") {
            url = {
              pathname: `/${props.locale}/${d.url}`,
              search: `?country=Angola&keyfigure=totalPop&type=abs&year=2015`,
            };
          }

          return (
            <li key={idx} className={classes.Mobile_Item}>
              <LinkItem
                type="main_nav_item"
                url={url}
                style={classes.Mobile_Item_Link}
                exact={false}
                item={d.item}

              />
            </li>
          );
        })}
      </ul>
      <ul>
        {/*<Localiser.Mobile*/}
        {/*  data={props.data.i18nSelector}*/}
        {/*  pathname={props.pathname}*/}
        {/*/>*/}
      </ul>
    </div>
  );
};

// function MobileMenuLevelOne(props) {
//   let style =
//     props.current.firstLevel === props.itemId
//       ? [classes.Mobile_Menu_Level1, classes.show].join(" ")
//       : classes.Mobile_Menu_Level1;

//   let headers = null;
//   if (props.data.menuHeader.list) {
//     headers = props.data.menuHeader.list.map((d, idx) => (
//       <li className={classes.Mobile_Item_2} key={idx}>
//         <LinkItem
//           type="main_nav_item"
//           url={{
//             pathname: `${props.locale}/explore`,
//             search: `?country=${d.url}&poprange=1,2,3,4,5,6&year=2015`,
//           }}
//           style={classes.Mobile_Item_Link}
//           item={d.item}
//         />
//       </li>
//     ));
//   }
//   return (
//     <ul className={style}>
//       {headers}
//       {props.data.menuItems.map((d, idx) =>
//         d.type === "selector" ? (
//           d.title.map((t, itx) => (
//             <li className={classes.Mobile_Item} key={itx}>
//               <button onClick={() => props.toggleForeward(t.id)}>
//                 {t.item}
//               </button>
//               <MobileNavLevelTwo
//                 {...props}
//                 data={d.list}
//                 itemId={t.id}
//                 countryMenuType={t.url}
//                 current={props.current.secondLevel}
//               />
//             </li>
//           ))
//         ) : (
//           <li className={classes.Mobile_Item} key={idx}>
//             <button onClick={() => props.toggleForeward(d.id)}>
//               {d.title}
//             </button>
//             <MobileNavLevelTwo
//               {...props}
//               data={d.list}
//               itemId={d.id}
//               current={props.current.secondLevel}
//             />
//           </li>
//         )
//       )}
//     </ul>
//   );
// }

// function MobileNavLevelTwo({ locale, countryMenuType, type, ...props }) {
//   let style =
//     props.current === props.itemId
//       ? [classes.Mobile_Menu_Level2, classes.show].join(" ")
//       : classes.Mobile_Menu_Level2;

//   return (
//     <ul className={style}>
//       {props.data.map((d, idx) => (
//         <li className={classes.Mobile_Item_2} key={idx}>
//           <LinkItem
//             type="main_nav_item"
//             url={
//               countryMenuType === "explore"
//                 ? {
//                     pathname: `${locale}/${countryMenuType}`,
//                     search: `?country=${d.item}&poprange=1,2,3,4,5,6&year=2015`,
//                   }
//                 : countryMenuType === "country-report"
//                 ? {
//                     pathname: `${locale}/${countryMenuType}/${toURLString(
//                       d.item
//                     )}`,
//                   }
//                 : { pathname: `${locale}/${toURLString(type)}/${d.url}` }
//             }
//             style={classes.Mobile_Item_Link}
//             exact={false}
//             item={d.item}
//           />
//         </li>
//       ))}
//     </ul>
//   );
// }
