import React from "react";
import classes from "./css/CountryReportChart.module.css";
import UrbanDynamics from "./Urbandynamics";
import { Treemap } from "d3plus-react";

const CountryReportChart = (props) => {
  return (
    <div className={classes.ChartWrapper}>
      {props.type === "Context" ? (
        <TheTreemap {...props} />
      ) : (
        <UrbanDynamics {...props} />
      )}
    </div>
  );
};

export default CountryReportChart;

const TheTreemap = (props) => {
  const agglomerations = [];
  props.agglosData.forEach((e) => {
    if (props.data.id[1] === e.ID[1] && e.uPop[e.uPop.length - 1] > 0) {
      agglomerations.push({ name: e.name, value: e.uPop[e.uPop.length - 1] });
    }
  });

  agglomerations.forEach((e) => {
    if (e.value < 30000) {
      e.group = "10 000 - 30 000";
    } else if (e.value < 100000) {
      e.group = "30 000 - 100 000";
    } else if (e.value < 300000) {
      e.group = "100 000 - 300 000";
    } else if (e.value < 1000000) {
      e.group = "300 000 - 1M";
    } else if (e.value < 3000000) {
      e.group = "1M - 3M";
    } else if (e.value < 10000000) {
      e.group = "3M - 10M";        
    } else {
      e.group = "Above 10M";
    }
  });

  const methods = {
    order: (a, b) => a.group - b.group,
    groupBy: ["group", "name"],
    data: agglomerations,
    size: (d) => d.value,
    height: 600,
  };

  return (
    <div className={classes.Treemap}>
      <Treemap config={methods} />
    </div>
  );
};
