import React from "react";
import { LinkItem } from "../header/index";
import classes from "./css/Menu.module.css";

export default ({ list, match, selectedMenu, toggleMenu }) => {
  return (
    <div className={classes.MenuHeader}>
      <header className="container">
        <ul className={classes.MenuList}>
          {list.map((d, idx) => {
            let handleClick = toggleMenu ? () => toggleMenu(d.value) : null;
            let style =
              selectedMenu === d.value
                ? [classes.MenuItem, classes.active].join(" ")
                : classes.MenuItem;
            return (
              <li key={idx} className={style} onClick={handleClick}>
                {toggleMenu ? (
                  d.label
                ) : (
                  <LinkItem
                    type="nav"
                    url={`${
                      d.value === "explore" ? match : match + "/country-report"
                    }`}
                    style={classes.MenuItem}
                    item={d.label}
                    exact={d.exact}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </header>
    </div>
  );
};
