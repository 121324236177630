export const Explore = {
  indicatorSeparator: (base, state) => {},
  dropdownIndicator: (base, state) => {
    return {
      ...base,
      color: "#2e2e2e",
    };
  },
  control: (base, state) => {
    return {
      ...base,
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "#2e2e2e",
      borderStyle: "none none solid none",
      borderWidth: "1px",
      color: "none",
      "&:hover": {
        // fill: "white",
      },
    };
  },
  valueContainer: (base, state) => {
    return {
      ...base,
      padding: "0",
    };
  },
  menu: (base, state) => {
    return {
      ...base,
      borderRadius: "0px",
    };
  },
  menuList: (base, state) => {
    return {
      ...base,
      padding: 0,
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      fontSize: "2rem",
      letterSpacing: "-1px",
      fontWeight: "900",
      margin: "15px",
      color: "#2e2e2e",
    };
  },
  input: (base, state) => {
    return {
      ...base,
      fontSize: "2rem",
    };
  },
  noOptionsMessage: (base, state) => {
    return {
      ...base,
      textAlign: "left",
    };
  },
};

export const CountryReport = {
  indicatorSeparator: (base, state) => {},
  dropdownIndicator: (base, state) => {
    return {
      ...base,
      color: "#2e2e2e",
    };
  },
  control: (base, state) => {
    return {
      ...base,
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "#2e2e2e",
      borderStyle: "none none solid none",
      borderWidth: "1px",
      color: "none",
      "&:hover": {
        // fill: "white",
      },
    };
  },
  valueContainer: (base, state) => {
    return {
      ...base,
      padding: "0",
    };
  },
  menu: (base, state) => {
    return {
      ...base,
      borderRadius: "0px",
    };
  },
  menuList: (base, state) => {
    return {
      ...base,
      padding: 0,
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      fontSize: "2rem",
      letterSpacing: "-1px",
      fontWeight: "900",
      margin: "15px",
      color: "#2e2e2e",
    };
  },
  input: (base, state) => {
    return {
      ...base,
      fontSize: "2rem",
    };
  },
  noOptionsMessage: (base, state) => {
    return {
      ...base,
      textAlign: "left",
    };
  },
};

export const DefaultStyle = {
  indicatorSeparator: (base, state) => {},
  dropdownIndicator: (base, state) => {
    return { ...base };
  },
  menu: (base, state) => {
    return { ...base, borderRadius: 0, zIndex: 100 };
  },
  option: (base, props) => {
    return {
      ...base,
      backgroundColor: props.isFocused
        ? "#EAC070"
        : props.isSelected
        ? "#e3e3e3"
        : "transparent",
      color: "#000",
    };
  },
  valueContainer: (base, state) => {
    return {
      ...base,
      //   fontSize: "1.25rem",
      fontWeight: "600",
    };
  },
  control: (base, value) => {
    return {
      ...base,
      borderRadius: 0,
      height: "50px",
      boxShadow: "none",
      backgroundColor: "none",
      borderColor: "#e3e3e3",
      color: "none",
      overflow: "hidden",
      "&:hover": {},
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      //   fontSize: "1.25rem",
      fontWeight: "600",
      color: "#212529",
    };
  },
  input: (base, state) => {
    return {
      ...base,
      //   fontSize: "2rem",
      fontWeight: "600",
      color: "#212529",
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    // fontSize: "1.25rem",
    fontWeight: 600,
    // lineHeight: "1rem",
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: data.color,
      color: "#C3533E",
    },
  }),
};
