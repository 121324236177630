import React from "react";
import { GridContainer } from "../../grids/index";
import classes from "./css/ArticleLayout.module.css";

export default ({ title, ...props }) => (
  <article className={classes.ArticleWrapper}>
    <GridContainer style={classes.ArticleContainer}>
      <header>
        <h1>{title}</h1>
      </header>
      {props.children}
    </GridContainer>
  </article>
);
