import React from "react";
import classes from "./SearchBar.module.css";
import Select, { components } from "react-select";
import { SelectorStyles } from "../../../../selectors";

const searchBar = (props) => {
  const { options, query, isMulti, grouped, queryType } = props;
  const flattenedOptions = grouped
    ? [...options[0].options, ...options[1].options]
    : options;

  let value = options.filter((d) => d.value === query[queryType])[0];
  if (isMulti) {
    let values = query[queryType].split(",");
    value = flattenedOptions.filter((d) => values.includes(d.value));
  }

  return (
    <div className={classes.SearchBar}>
      <Select
        value={value}
        isMulti={props.isMulti}
        onChange={props.onSearchChange}
        options={options}
        isSearchable={false}
        isClearable={false}
        backspaceRemoves={false}
        deleteRemoves={false}
        hideSelectedOptions={false}
        styles={SelectorStyles.DefaultStyle}
        components={{ ValueContainer }}
      />
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const length = children[0].length;
  let tmpChildren = [...children];
  if (length >= 4) {
    tmpChildren[0] = `${length} countries selected`;
  }
  return (
    <components.ValueContainer {...props}>
      {tmpChildren}
    </components.ValueContainer>
  );
};

export default searchBar;
