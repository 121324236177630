import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Legend,
  YAxis,
  XAxis,
  Line,
  Tooltip,
  CartesianGrid,
} from "recharts";
import classes from "./LineGraph.module.css";

const lineGraph = (props) => {
  const { data, locale, query } = props;
  const sepQuery = query.country.split(",");
  const colorBasket = [
    "#a70000",
    "#016FB9",
    "#353531",
    "#FF9505",
    "#EC4E20",
    "#D5E0C8",
    "#A36E52",
    "#D5C73E",
    "#D0AACF",
    "#2D9E48",
    "#7F97BC",
    "#68D7DA",
  ];

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart data={data.filter((d) => d.ID === query.keyfigure)}>
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis
          dataKey="Year"
          domain={[1950, 2050]}
          type="number"
          ticks={[1950, 1960, 1970, 1980, 1990, 2000, 2010, 2015, 2020, 2025, 2030, 2035, 2040, 2045, 2050]}
        />
        <YAxis
          type="number"
          strokeWidth="0px"
          stroke="black"
          tick={{ fontSize: "11px" }}
        />
        <Tooltip
          cursor={{ strokeWidth: 0 }}
          content={renderTooltip}
          type={data.ID}
          language={locale}
        />
         {/* {sepQuery.map((e, j) => (
          <Line
            dataKey={e}
            key={j}
            stroke={colorBasket[j]}
            strokeWidth={2}
            type={query.type === "growth" ? "stepBefore" : "monotone"}
          />
        ))}  */}
      {sepQuery.map((e, j) => (
        <React.Fragment key={e}>
        <Line
          dataKey={e}
          key={`${j}-solid`}
          data={data.filter(d => d.Year < 2025 && d.ID === query.keyfigure)}
          stroke={colorBasket[j]}
          strokeWidth={2}
          type={query.type === "growth" ? "stepBefore" : "monotone"}
        />
        <Line
          dataKey={e}
          legendType="none"
          tooltipType="none"
          key={`${j}-dashed`}
          data={data.filter(d => d.Year >= 2020 && d.ID === query.keyfigure)}
          stroke={colorBasket[j]}
          strokeWidth={2}
          strokeDasharray="5 5"
          dot={{ strokeDasharray: '' }}
          connectNulls={true}
          type={query.type === "growth" ? "stepBefore" : "monotone"}
        />
        </React.Fragment>
      ))}
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default lineGraph;

const renderTooltip = (props) => {
  if (props.active && props.payload !== null && props.payload[0] !== null) {
    let payload = props.payload[0].payload;
    let tooltip = null;
    switch (props.payload[0].payload.ID) {
      case "totalPop":
      case "ruralPop":
      case "urbanPop":
        if (props.payload[0].payload.type === "abs") {
          tooltip = (
            <div className={classes.Tooltip}>
              <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                {payload.Year} <small>(million inhabitants)</small>{" "}
              </p>
              {/* {console.log(props.payload)} */}
              {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value / 10000) / 100}m
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
              {/* {props.payload.map((i, idx) => (
                if (idx % 2 == 0 {
                <p
                  key={idx}
                  style={{
                    color: i.color,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  {i.dataKey}: {Math.round(i.value / 10000) / 100}m
                </p>
             }))} */}
            </div>
          );
        } else if (props.payload[0].payload.type === "baseYear") {
          tooltip = (
            <div className={classes.Tooltip}>
              <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                {payload.Year} <small>(1990 = 100x)</small>{" "}
              </p>
              {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value * 100) / 100}x
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
              {/* {props.payload.map((i, idx) => (
                <p
                  key={idx}
                  style={{
                    color: i.color,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  {i.dataKey}: {Math.round(i.value * 100) / 100}x
                </p>
              ))} */}
            </div>
          );
        } else if (props.payload[0].payload.type === "growth") {
          tooltip = (
            <div className={classes.Tooltip}>
              <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                {payload.Year === 1950
                  ? "1950-1960"
                  : payload.Year === 2015
                  ? "2010-2015"
                  : payload.Year - 5 + "-" + payload.Year}{" "}
                <small>(Annual growth rate)</small>{" "}
              </p>
              {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value * 100) / 100}%
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
              {/* {props.payload.map((i, idx) => (
                <p
                  key={idx}
                  style={{
                    color: i.color,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  {i.dataKey}: {Math.round(i.value * 100) / 100}%
                </p>
              ))} */}
            </div>
          );
        }
        break;
      case "urbanLevel":
        tooltip = (
          <div className={classes.Tooltip}>
            <p style={{ marginLeft: "10px", marginRight: "10px" }}>
              {payload.Year} <small>(urban pop/total pop)</small>{" "}
            </p>
            {props.payload.map((i, idx) => {
              if (idx % 2 === 0) {
                return (
                  <p
                    key={idx}
                    style={{
                      color: i.color,
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    {i.dataKey}: {Math.round(i.value * 100)}%
                  </p>
                )
              }
              return null; // Optionally return null for odd indices
            })}
            {/* {props.payload.map((i, idx) => (
              <p
                key={idx}
                style={{
                  color: i.color,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {i.dataKey}: {Math.round(i.value * 100)}%
              </p>
            ))} */}
          </div>
        );
        break;
      case "numAgglos":
        tooltip = (
          <div className={classes.Tooltip}>
            <p style={{ marginLeft: "10px", marginRight: "10px" }}>
              {payload.Year} <small>(agglomerations)</small>{" "}
            </p>
            {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value)}
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
            {/* {props.payload.map((i, idx) => (
              <p
                key={idx}
                style={{
                  color: i.color,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {i.dataKey}: {Math.round(i.value)}
              </p>
            ))} */}
          </div>
        );
        break;
      case "mPop":
        tooltip = (
          <div className={classes.Tooltip}>
            <p style={{ marginLeft: "10px", marginRight: "10px" }}>
              {payload.Year} <small>(metro pop/total pop)</small>{" "}
            </p>
            {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value *100)}%
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
            {/* {props.payload.map((i, idx) => (
              <p
                key={idx}
                style={{
                  color: i.color,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {i.dataKey}: {Math.round(i.value * 100)}%
              </p>
            ))} */}
          </div>
        );
        break;
      case "ADBA":
        tooltip = (
          <div className={classes.Tooltip}>
            <p style={{ marginLeft: "10px", marginRight: "10px" }}>
              {payload.Year} <small>(kilometres)</small>{" "}
            </p>
            {props.payload.map((i, idx) => {
                if (idx % 2 === 0) {
                  return (
                    <p
                      key={idx}
                      style={{
                        color: i.color,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {i.dataKey}: {Math.round(i.value)}km
                    </p>
                  )
                }
                return null; // Optionally return null for odd indices..
              })}
            {/* {props.payload.map((i, idx) => (
              <p
                key={idx}
                style={{
                  color: i.color,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {i.dataKey}: {Math.round(i.value)}km
              </p>
            ))} */}
          </div>
        );
        break;
      default:
        tooltip = null;
    }

    return tooltip;
  }
  return null;
};
