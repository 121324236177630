import React from "react";
import AsyncSelect from "react-select/async";
import classes from "./css/Select.module.css";
import { toJSONString } from "../../global/index";

export default ({ filterVariable, options, styles, push, query }) => {

  const filterCountries = (inputValue) => {
    let value = toJSONString(inputValue);
    return options.filter((i) => i.label.toLowerCase().includes(value));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterCountries(inputValue));
    }, 1000);
  };

  const valueShown = query ? (query.agglomeration ? query.agglomeration + ", " + query.country : query.country) : filterVariable
  const optionShown = {value: valueShown, label: valueShown}

  return (
    <>
      <AsyncSelect
        value={optionShown}
        loadOptions={loadOptions}
        className={classes.select}
        styles={styles}
        noOptionsMessage={() => "Search for a country or an agglomeration"}
        onChange={(value) => push(value)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#e3e3e3",
            primary50: "grey",
            primary: "grey",
          },
        })}
      />
    </>
  );
};
