import React from "react";
import CountryReportChart from "./CountryReportChart";
import classes from "./css/CountryReportContent.module.css";

const CountryReportContent = ({ data, children, ...props }) => {
  let tempdata = Object.entries(data.list[0].item);
  let result = [];
  tempdata.forEach((d) => {
    result.push({ id: d[0], text: d[1] });
  });

  return (
    <div className={classes.cr_content_offset}>
      {children}
      {result.map((d, i) => {
        let bg = d.id === "Territorial" ? "#f4f4f4" : "none";
        let hasChart = d.id === "Context" || d.id === "Dynamics" ? true : false;
        let textcontent = d.text.map((text, i) => (text.substring(0,1)==="@"? <p key={i}> <b> {text.substring(1)} </b> </p> : text.substring(0,4)==="<li>"? <p key={i}> <li>{text.substring(4)}</li> </p> : text.substring(0,6)==="#image"? <img src ={'/images/countrynote/'+text.substring(6)+'.png'} width={600} />: <p key={i}> {text} </p> ))
        return (
          <div
            key={i}
            className={classes.cr_content_wrapper}
            style={{ backgroundColor: bg }}
          >
            <div className={classes.cr_content_container}>
              <div className={classes.cr_content_section}>
                <div className={classes.cr_content_section__inner}>
                  <div className={classes.cr_content_section__text}>
                    <div className={classes.cr_content_text__title}>{d.id}</div>
                    <div className={classes.cr_content_text__content}>
                      {textcontent}
                    </div>
                  </div>
                  {hasChart && (
                    <div className={classes.cr_content_section__graph}>
                      <CountryReportChart {...props} data={data} type={d.id} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
          className={classes.cr_content_wrapper}
      >
        <div className={classes.cr_content_container}>
          <div className={classes.cr_content_section}>
            <div className={classes.cr_content_section__inner}>
              <div className={classes.cr_content_section__text}>
                <div className={classes.cr_content_text__title}>{props.locale =="en" ? "Socio-economic indicators" : "Indicateurs socio-économiques"}</div>
                <div className={classes.cr_content_text__content}>
                  {props.locale =="en" ?
                      <>
                      <p>In the report <a href="https://doi.org/10.1787/2074353x">“Africa’s Urbanisation Dynamics 2022, The Economic Power of Africa’s Cities”</a>, the <a href="https://www.oecd.org/swac/">Sahel and West Africa Club</a> collected and analysed the results of two continent-wide surveys: the <a href="https://dhsprogram.com/">Demographic and Health Surveys</a> and the <a href="https://www.worldbank.org/en/programs/lsms">Living Standard Measurement Study</a>.  Our team matched the location of these results to the agglomerations in Africapolis, allowing to compare socio-economic performance across different geographical scales and city sizes. This analysis presented compelling evidence that urbanisation contributes to better economic outcomes and higher standards of living.</p>
                        <i>Explore data from more than 4 million individuals and firms across 2 600 cities and 34 African countries and see how urbanisation and city size drive economic performance in Africa.  </i>
                      </>
                      : <>
                        <p>Dans le rapport <a href="https://doi.org/10.1787/aa4762cf-fr">“Dynamiques de l’urbanisation africaine 2022, Le rayonnement économique des villes africaines”</a>, le <a href="https://www.oecd.org/fr/csao/">Club du Sahel et de l’Afrique de l’Ouest</a> a recueilli et analysé deux enquêtes menées au niveau continentale : l’<a href="https://dhsprogram.com/">Enquête démographique et de santé</a> et le <a href="https://www.worldbank.org/en/programs/lsms">Living Standard Measurement Study</a>.  Notre équipe a pu apparier la localisation de ces résultats aux agglomérations d’Africapolis, permettant de comparer la performance socio-économique de différentes échelles géographiques et tailles de villes. Dans la plupart des dimensions socio économiques, les villes obtiennent des résultats nettement supérieurs aux pays au sein desquels elles se situent.</p>
                        <i>Explorez les données recueillies auprès de plus de 4 millions d&#39;individus et d&#39;entreprises, issus de 2 600 villes de 34 pays.</i>
                      </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryReportContent;
