import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout/index";
import { Grid, GridWrapper, GridContainer } from "../../modules/grids/index";
import { Route, Switch } from "react-router-dom";
import { Article } from "./index";
import * as actions from "../../store/actions/index";
import classes from "./css/About.module.css";
import {AboutNewsletter} from "../../modules/grids/components/GridComponents";

export default (props) => {
  const { url, path } = props.match,
    { locale } = props.match.params;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.about.data);

  const stableDispatch = useCallback(() => {
    dispatch(actions.fetchAboutData(locale));
  }, [locale, dispatch]);

  useEffect(() => {
    return stableDispatch();
  }, [stableDispatch]);

  const fetchData = {
    fetch: (m, s) => actions.fetchNarrativeData(m, s),
    clear: () => actions.clearNarrativeData(),
  };

  if (Object.keys(data).length === 0) {
    return null;
  } else {
    return (
      <Switch>
        <Route path={path} exact>
          <ContainerType.Text {...props} data={data}>
            <GridWrapper type="intro">


              <GridContainer
                  style={classes.Container}
                  header
                  title={data.newsletter.title}
                  type="twelve"
              >
                <div className={classes.subscribe}>
                  {locale==="en" ? "Subscribe to our newsletter!" : "Abonnez-vous à nos lettres d'information !"}
                  <div></div>
                <a href={"https://oecd.us4.list-manage.com/subscribe?u=5aa4680998eddebe5f4ce7065&id=17db0c31dd"}> {locale==="en" ? "Subscribe" : "S'abonner"} </a>
                </div>

                <Grid.AboutNewsletter
                    data={data.newsletter.list}
                    style={classes.Item}
                />
              </GridContainer>


              <GridContainer
                style={classes.Container}
                header
                title={data.narratives.title}
                type="twelve"
              >
                <Grid.AboutNarratives
                  data={data.narratives.list}
                  style={classes.Item}
                  match={url}
                />
              </GridContainer>

            </GridWrapper>
            <GridWrapper>
              {data.about.map((d, idx) => (
                <GridContainer
                  key={idx}
                  style={classes.AboutContainer}
                  header
                  title={d.title}
                  type="twelve"
                >
                  <Grid.About
                    data={d.list}
                    images={d.images}
                    style={classes.AboutText}
                  />
                </GridContainer>
              ))}
            </GridWrapper>
          </ContainerType.Text>
        </Route>
        <Route
          path={`${path}/:story`}
          render={(props) => (
            <Article {...props} fetchData={fetchData} parent={"about"} />
          )}
        />
      </Switch>
    );
  }
};
