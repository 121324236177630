import React from "react";
import { HeaderItems } from "../index";
import { Image } from "../../../static";
import { Link } from "react-router-dom";
import { MobileHeaderButton } from "../../../static";
import classes from "./css/Header.module.css";

export default (props) => {
  const { data, baseURL, mouseOver, locale, pathname } = props;
  let logo = pathname.length > 3 ? data.logo.light : data.logo.dark;
  return (
    <div className={classes.Header}>
      <Link to={{ pathname: `/${locale}` }}>
        <Image
          imageWrapper={classes.Logo}
          src={`/images/logo/${logo}.svg`}
          alt="Africapolis"
        />
      </Link>
      <nav>
        <HeaderItems
          type="web"
          data={data}
          pathname={pathname}
          locale={locale}
          baseURL={baseURL}
          mouseOver={mouseOver}
          toggleHover={props.toggleHover}
          toggleLeave={props.toggleLeave}
          mobileHeaderToggle={props.mobileHeaderToggle}
          subHeaderClick={props.subHeaderClick}
        />
      </nav>
      <MobileHeaderButton
        pathname={pathname}
        mobileHeaderToggle={props.mobileHeaderToggle}
      />
    </div>
  );
};
