import React, { useState } from "react";
import { Button, TextLink } from "../../static/index";
import classes from "./css/Modal.module.css";

export const Text = ({ data, open, ...props }) => {
  return (
    <div>
      <h4>{data.text}</h4>
      <Button
        title={open ? "Close" : data.title}
        toggleButton={props.toggleButton}
      />
      <article
        className={
          open ? [classes.ModalText, classes.show].join(" ") : classes.ModalText
        }
      >
        {data.content.list.map((d, idx) => {
          let tempLinks = {};
          for (let item in d.links)
            tempLinks[d.links[item].match] = d.links[item].link;

          return <TextLink key={idx} text={d.item} links={tempLinks} />;
        })}
      </article>
    </div>
  );
};

export const Video = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button title={data.title} toggleButton={() => setOpen(!open)} />
      <article>Description</article>
    </div>
  );
};
