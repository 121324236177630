import React from "react";
import { KeyFigure } from "../../keyfigures";
import classes from "./css/CountryReportMixer.module.css";
export default ({ data, countryList, ...props }) => {
  return (
    <header className={classes.Header}>
      <div className={classes.HeaderContainer}>
        <div className={classes.HeaderItems}>
          <div className={classes.HeaderTitle}>
            <h3>{props.match.params.country}</h3>
          </div>
          <KeyFigure data={data.keyFigures} />
        </div>
      </div>
    </header>
  );
};
