import React from "react";
import SearchBar from "../SearchBar/SearchBar";
import { pushQuery } from "../../../../global";
import classes from "./Mixer.module.css";

const mixer = ({ countryList, query, keyFigureList, history }) => {
  const keyfigureOptions = keyFigureList.map((d) => ({
    value: d.type,
    label: d["label"],
    description: d["description"],
  }));

  function handleChange(e, d) {
    if (e === null) {
      return;
    } else {
      const newCountries = e.map((d) => d.value);
      pushQuery(history, { country: newCountries });
    }
  }
  return (
    <div className={classes.Mixer}>
      <SearchBar
        grouped
        isMulti={true}
        query={query}
        queryType="country"
        options={countryList}
        onSearchChange={handleChange}
      />
      <SearchBar
        isMulti={false}
        query={query}
        queryType="keyfigure"
        options={keyfigureOptions}
        onSearchChange={(e) => {
            pushQuery(history, { keyfigure: e.value})
            pushQuery(history, { type : "abs" })
        }
        }
      />
    </div>
  );
};

export default mixer;
