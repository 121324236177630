import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainerType } from "../../modules/layout/index";
import * as actions from "../../store/actions/index";
import CountryReportContent from "./CountryReportContent";
import { Icon } from "../../modules/static";
import { MobileHeaderWrapper } from "../../modules/headers/header";
import { AsyncSelect, SelectorStyles } from "../../modules/selectors";
import classes from "./css/CountryReport.module.css";
import CountryReportChart from "./CountryReportChart";

export default (props) => {
  const { locale, country } = props.match.params;
  const { location, i18n } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.countries);
  const [menuOpen, setMenuOpen] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const stableDispatchInitialFetch = useCallback(() => {
    dispatch(actions.initialFetchCountryData(locale, country));
  }, [locale, dispatch, country]);

  // Fetch country data once.
  useEffect(() => {
    stableDispatchInitialFetch();
    return () => actions.clearCountriesData();
  }, [stableDispatchInitialFetch]);

  useEffect(() => {
    dispatch(
      actions.filterCountryData(
        data.countryData,
        country.charAt(0).toUpperCase() + country.slice(1)
      )
    );
    return () => actions.clearCountriesData();
  }, [dispatch, country, data.countryData]);

  const stableFetchCountryList = useCallback(() => {
    dispatch(actions.fetchCountryList(locale));
  }, [locale, dispatch]);

  useEffect(() => {
    stableFetchCountryList();
    return () => actions.clearCountriesData();
  }, [stableFetchCountryList]);
  const { agglosData, countryList, selectedCountry } = data;

  if (
    Object.keys(countryList).length === 0 ||
    Object.keys(selectedCountry).length === 0
  ) {
    return null;
  } else {
    let options = [];
    let tempdata = data.countryData.filter((d) => d.list).map((d) => d.id[1]);
    data.countryList.countryList.forEach((d) => {
      let id = tempdata.findIndex((t) => t === d.C_ID);
      if (id !== -1) {
        options.push(d);
      }
    });

    return (
      <>
        <MobileHeaderWrapper
          data={i18n}
          mobileToggle={menuOpen}
          pathname={location.pathname}
          locale={locale}
          closed={() => setMenuOpen(!menuOpen)}
        />
        <div id="keyfigures" className={classes.ReportWrapper}>
          <ContainerType.CountryReport
            {...props}
            locale={locale}
            countryList={countryList}
            selectedCountry={selectedCountry}
          />
        </div>
        <CountryReportContent
          data={selectedCountry}
          regiondata={data.countryData}
          regionlist={data.countryList.countryList}
          agglosData={agglosData}
          locale={locale}
        >
          <nav className={classes.CountryReportNav}>
            <div className={classes.CountryReportNav__inner}>
              <div className={classes.CountryReport__search}>
                <AsyncSelect
                  filterVariable={props.match.params.country}
                  styles={SelectorStyles.DefaultStyle}
                  options={options.map((d) => ({
                    value: d.C_ID,
                    label: d.item,
                  }))}
                  push={(value) =>
                    props.history.push({
                      pathname: `/${locale}/country-report/${value.label}`,
                    })
                  }
                />
              </div>
              <div className={classes.CountryReportNavToggle}>
                <Icon icon="ham" click={() => setMenuOpen(!menuOpen)} />
              </div>
            </div>
          </nav>
        </CountryReportContent>
       <iframe src="https://mkmdivy.github.io/pacdigitalstory/" width={windowSize.current[0]>=800 ? "800px" : windowSize.current[0]} height={windowSize.current[1]>1000 ? "600px" : windowSize.current[1]} style={{marginLeft: windowSize.current[0]>=800 ? windowSize.current[0]/4 : 0, border: 'none'}} />
      </>
    );
  }
};
