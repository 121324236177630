import React from "react";
import { Spring } from "react-spring/renderprops";
import { valueType, unitType } from "../../global/index";
import classes from "./css/KeyFigureComponents.module.css";

export default ({ title, type, value }) => {
  return (
    <div className={classes.KeyFigureItem}>
      <span>{title}</span>

        {title == 'Urban area / total area' || title == 'Superficie urbaine / superficie totale'  ? <h6>{Math.round(value * 100000)/1000} <span>{unitType(type)}</span></h6>:
      <Spring
        from={{ number: 0 }}
        to={{ number: value }}
        config={{
          duration: 1000,

        }}
      >
        {(props) => (
          <h6>
            {title =='Urban area / total area' || title == 'Superficie urbaine / superficie totale' ? Math.round(props.number * 10000)/100 : valueType(type, props.number)} <span>{unitType(type)}</span>
          </h6>
        )}
      </Spring>}


    </div>
  );
};
