import React from "react";
import classes from "./MobileHeaderButton.module.css";
import Icon from "../icons/Icons";

export default (props) => {
  let dark = props.pathname.length > 3 ? false : true;
  return (
    <div className={classes.MobileHeaderButton}>
      <Icon
        dark={dark}
        icon="ham"
        title="Menu"
        click={props.mobileHeaderToggle}
      />
    </div>
  );
};
