import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Viz } from "../../modules/visualisations/index";
import { MobileHeaderWrapper } from "../../modules/headers/header";
import { ExploreMixer as Mixer } from "../../modules/mixer/index";
import { Icon } from "../../modules/static";
import * as actions from "../../store/actions/index";
import classes from "./css/Explore.module.css";
import {pushQuery} from "../../modules/global";
import {countryNameToISO3} from "../../modules/visualisations/components/MapComponents/mapUtils";

export default (props) => {
  const { locale } = props.match.params;
  const { history, location, q, i18n } = props;

  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const data = useSelector((state) => state.countries);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [layer, setLayer] = useState("base");
  const [buildUpYear, setBuildUpYear] = useState(2020);

  const stableDispatchInitialFetch = useCallback(() => {
    dispatch(actions.initialFetchCountryData(locale, q.country));
  }, [locale, dispatch, q.country]);

  // Fetch country data once.
  useEffect(() => {
    stableDispatchInitialFetch();
    return () => actions.clearCountriesData();
  }, [stableDispatchInitialFetch]);

  // useEffect(() => {
  //   dispatch(actions.filterCountryData(data.countryData, q.country));
  //   return () => actions.clearCountriesData();
  // }, [dispatch, q.country, data.countryData]);
  //
  // useEffect(() => {
  //   dispatch(actions.filterAgglosData(data.agglosData, q.agglomeration));
  //   return () => actions.clearCountriesData();
  // }, [dispatch, q.agglomeration, data.agglosData]);


  useEffect(() => {
      dispatch(actions.filterAgglosData(data.agglosData, q.agglomeration, countryNameToISO3[q.country]));
      dispatch(actions.filterCountryData(data.countryData, q.country));
      return () => actions.clearCountriesData();
    }, [dispatch, q.country, q.agglomeration, data.agglosData, data.countryData]);


  const stableFetchCountryList = useCallback(() => {
    dispatch(actions.fetchCountryList(locale));
  }, [locale, dispatch]);

  useEffect(() => {
    stableFetchCountryList();
    return () => actions.clearCountriesData();
  }, [stableFetchCountryList]);

  const { countryList, selectedCountry, selectedAgglos } = data;


  if (
    Object.entries(selectedCountry).length === 0 ||
    Object.entries(countryList).length === 0
  ) {
    return null;
  }
  console.log(selectedAgglos)

  return (
    <div className={classes.ExploreWrapper}>

        <div className={sidebarOpen ? classes.button_collapse : classes.button_expand} onClick={() => setSidebarOpen(!sidebarOpen)} />
      <Mixer
        query={q}
        data={data}
        locale={locale}
        history={history}
        countryList={countryList.all}
        open={sidebarOpen}
        layer = {layer}
        setLayer = {setLayer}
        buildUpYear = {buildUpYear}
        setBuildUpYear = {setBuildUpYear}

      />
      <MobileHeaderWrapper
        data={i18n}
        mobileToggle={menuOpen}
        pathname={location.pathname}
        locale={locale}
        closed={() => setMenuOpen(!menuOpen)}
      />
      <div className={classes.ExploreNavToggle}>
        <Icon icon="ham" click={() => setMenuOpen(!menuOpen)} />
      </div>
      <Viz.Legend
        query={q}
        history={history}
        selectedCountry={selectedCountry}
        data={data.agglosData}
      />
      <Viz.Map
        styles={classes.MapWrapper}
        selectedCountry={selectedCountry}
        selectedAgglos={selectedAgglos}
        history={history}
        query={q}
        layer={layer}
        buildUpYear = {buildUpYear}
        locale={locale}
      />
    </div>
  );
};
