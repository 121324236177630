import queryString from "query-string";

export const yearRange = [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2015, 2020, 2025, 2030, 2035, 2040, 2045, 2050];

export function valueType(type, value) {
  switch (type) {
    case "number":
      return Number(value.toFixed())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    case "distance":
      return Number(value.toFixed())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    case "percentage":
      return Math.round(value * 100);
    case "area":
      return Number(value.toFixed())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    case "areaPer":
      return Number(value.toFixed())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    default:
      return null;
  }
}
export function unitType(type) {
  switch (type) {
    case "number":
      return "";
    case "distance":
      return "km";
    case "percentage":
      return "%";
    case "area":
      return "km²";
    case "areaPer":
      return "hab/km²";
    default:
      return null;
  }
}
export function toJSONString(value) {
  return value.replace(/-/g, " ").toLowerCase();
}
export function toURLString(value) {
  return value.replace(/\s+/g, "-").toLowerCase();
}
export function pushQuery(history, incomingQuery) {
  /**
   * If incoming query is agglomeration,
   * Check if the agglomeration is part of the already selected country
   *
   * If YES,
   * continue with code,
   * If NOT,
   * change the country and
   */

  const incomingQueryKey = Object.keys(incomingQuery)[0];

  const prevQuery = queryString.parse(history.location.search, {
    arrayFormat: "comma",
    parseNumbers: true,
  });


  if (incomingQueryKey === "country" && prevQuery.agglomeration) {
    prevQuery[incomingQueryKey] = incomingQuery[incomingQueryKey];
    prevQuery["agglomeration"] = "";
  }

  if (incomingQueryKey === "agglomeration" && incomingQuery["country"] && prevQuery["country"] != incomingQuery.country){
    prevQuery["country"] = incomingQuery.country
  }

  prevQuery[incomingQueryKey] = incomingQuery[incomingQueryKey];

  const newQueries = queryString.stringify(prevQuery, {
    skipEmptyString: true,
    arrayFormat: "comma",
    parseNumbers: true,
  });

  return history.push({
    pathname: history.pathname,
    search: `?${newQueries}`,
  });
}
